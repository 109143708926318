<!-- -->
<template>
  <div class="frame">
    <BreadCrumb />
    <Banner />
    <div class="subject">
      <div class="box1">
        <div class="subjectNav f-cb">
          <a
            href="javascript:;"
            style="margin-right: 10px"
            :class="{ on: activeType == item.officeName }"
            v-for="item in subjectList"
            :key="item.id"
            @click="changeType(item)"
            >{{ item.officeName }}</a
          >
        </div>
        <div class="itemBox">
          <div
            class="item"
            v-for="subject in subjectList"
            :key="subject.id"
            v-show="activeType == subject.officeName"
          >
            <ul class="f-cb item-list">
              <li v-for="(item, idx) in subject.children" :key="item.id">
                <a href="javascript:;" @click="changeSubject(item)">
                  <div class="icon" v-if="idx < 11">
                    <img
                      width="100%"
                      :src="
                        'https://www.sclsnk.com/file/mskin/picture/about_img' +
                        (idx + 1) +
                        '.png'
                      "
                    />
                  </div>
                  <div class="icon" v-else>
                    <img
                      width="100%"
                      src="https://www.sclsnk.com/file/mskin/picture/about_img12.png"
                    />
                  </div>
                  <p>{{ item.officeName }}</p>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="mainLine"></div>
      <div class="box2">
        <div class="title">{{ activeSubject.officeName }}</div>
        <div class="list f-cb">
          <br />
          <p v-html="activeSubject.content"></p>
        </div>

        <div class="pic">
          <img width="100%" :src="activeSubject.imageUrl" />
        </div>
      </div>
      <div class="mainLine"></div>

      <div class="box4">
        <div class="aboutTitle">{{ activeSubject.officeName }}专家介绍</div>
        <div id="swiper" class="list">
          <ul class="f-cb swiper-wrapper">
            <li class="swiper-slide" v-for="item in proList" :key="item.id">
              <router-link :to="'teamlist_3/' + item.id">
                <div class="pic">
                  <img width="100%" :src="item.imageHeaderUrl" />
                </div>
                <div class="tits">{{ item.name }}</div>
                <div class="tit">{{ item.position }}</div>
              </router-link>
            </li>
          </ul>
          <div class="btns"></div>
        </div>
      </div>

      <div class="subjectTable" style="display: none">
        <div class="aboutTitle f-cb">
          {sort:name}排班表
          <div class="aBox f-cb"></div>
        </div>

        <div class="itemBox" id="calendar">
          <div class="item" style="display: block">
            <div class="tableA">
              <table>
                <tbody>
                  <img width="100%" src="{label:paibanm}" />
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="aboutTitle f-cb"></div>
      </div>
      <div class="mainLine"></div>
      <div class="box5">
        <div class="aboutTabs aboutTabs1 f-cb"></div>
        <div class="aboutConts aboutConts1"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Banner from "@/components/mBanner.vue";
import BreadCrumb from "@/components/breadCrumb.vue";
import { Store } from "@/store/index.js";
import { mapState } from "pinia";
export default {
  data() {
    return {
      activeType: "", // 科室类型
      activeSubject: {}, // 具体科室
      proList: [],
      swiper: null,
      activeSwiper: 0,
      newQueryParams: {
        pageIndex: 1,
        pageSize: 100,
      },
      newList: [
        {
          id: 1,
          title:
            "与时间赛跑，为生命接力 | 四川蓝生脑科医院急救“绿色通道”成功救治大面积脑出血患者",
          post: "https://www.sclsnk.com/static/upload/image/20240724/1721780543195532.jpg",
        },
        {
          id: 2,
          title:
            "院士专家齐聚蓉城 共话脑科前沿技术——首届四川脑科论坛暨四川省医学传播学会神经外科专委会成立及学术会议在四川蓝生脑科医院隆重举行",
          post: "https://www.sclsnk.com/static/upload/image/20231107/1699317339551260.jpg",
        },
        {
          id: 3,
          title:
            "与时间赛跑，为生命接力 | 四川蓝生脑科医院急救“绿色通道”成功救治大面积脑出血患者",
          post: "https://www.sclsnk.com/static/upload/image/20240724/1721780543195532.jpg",
        },
        {
          id: 4,
          title:
            "院士专家齐聚蓉城 共话脑科前沿技术——首届四川脑科论坛暨四川省医学传播学会神经外科专委会成立及学术会议在四川蓝生脑科医院隆重举行",
          post: "https://www.sclsnk.com/static/upload/image/20231107/1699317339551260.jpg",
        },
        {
          id: 5,
          title:
            "与时间赛跑，为生命接力 | 四川蓝生脑科医院急救“绿色通道”成功救治大面积脑出血患者",
          post: "https://www.sclsnk.com/static/upload/image/20240724/1721780543195532.jpg",
        },
        {
          id: 6,
          title:
            "院士专家齐聚蓉城 共话脑科前沿技术——首届四川脑科论坛暨四川省医学传播学会神经外科专委会成立及学术会议在四川蓝生脑科医院隆重举行",
          post: "https://www.sclsnk.com/static/upload/image/20231107/1699317339551260.jpg",
        },
        {
          id: 7,
          title:
            "与时间赛跑，为生命接力 | 四川蓝生脑科医院急救“绿色通道”成功救治大面积脑出血患者",
          post: "https://www.sclsnk.com/static/upload/image/20240724/1721780543195532.jpg",
        },
        {
          id: 8,
          title:
            "院士专家齐聚蓉城 共话脑科前沿技术——首届四川脑科论坛暨四川省医学传播学会神经外科专委会成立及学术会议在四川蓝生脑科医院隆重举行",
          post: "https://www.sclsnk.com/static/upload/image/20231107/1699317339551260.jpg",
        },
        {
          id: 9,
          title:
            "与时间赛跑，为生命接力 | 四川蓝生脑科医院急救“绿色通道”成功救治大面积脑出血患者",
          post: "https://www.sclsnk.com/static/upload/image/20240724/1721780543195532.jpg",
        },
        {
          id: 10,
          title:
            "院士专家齐聚蓉城 共话脑科前沿技术——首届四川脑科论坛暨四川省医学传播学会神经外科专委会成立及学术会议在四川蓝生脑科医院隆重举行",
          post: "https://www.sclsnk.com/static/upload/image/20231107/1699317339551260.jpg",
        },
      ],
    };
  },
  components: { Banner, BreadCrumb },
  computed: {
    ...mapState(Store, ["subjectList"]),
  },
  watch: {
    subjectList(val) {
      if (val) {
        this.activeType = val[0].officeName;
        this.activeSubject = val[0].children[0];
        if (this.$route.query.type) {
          let arr = this.flatArr(val);
          const item = arr.find((ele) => ele.id == this.$route.query.type);
          this.activeSubject = item;
        }
        this.getDoctor();
      }
    },
  },
  created() {
    if (this.subjectList.length != 0) {
      this.activeType = this.subjectList[0].officeName;
      this.activeSubject = this.subjectList[0].children[0];
      if (this.$route.query.type) {
        let arr = this.flatArr(this.subjectList);
        const item = arr.find((ele) => ele.id == this.$route.query.type);
        this.activeSubject = item;
      }
      this.getDoctor();
    }
  },
  methods: {
    flatArr(arr) {
      return arr.flatMap((ele) => ele.children);
    },
    changeType(item) {
      this.activeType = item.officeName;
    },
    changeSubject(child) {
      this.activeSubject = child;
      this.getDoctor();
    },
    getDoctor() {
      this.$api
        .getAllDoctor({ administrativeOffice: this.activeSubject.officeName })
        .then((res) => {
          this.proList = res.data;
          setTimeout(() => {
            this.initSwiper();
          }, 100);
        });
    },
    initSwiper() {
      let vm = this;
      this.swiper = new window.Swiper("#swiper", {
        slidesPerView: 3, // 一页显示数量
        spaceBetween: 9,
        pagination: {
          el: ".btns",
        },
        on: {
          slideChangeTransitionEnd() {
            vm.activeSwiper = this.activeIndex;
          },
        },
      });
    },
    changeSwiper(idx, type) {
      if (type == "prev") {
        this.swiper.slidePrev();
        return;
      }
      if (type == "next") {
        this.swiper.slideNext();
        return;
      }
      this.swiper.slideTo(idx);
    },
  },
};
</script>

<style lang="scss" scoped>
.item-list {
  display: flex;
  flex-wrap: wrap;
}
.item-list li {
  float: none !important;
}

#swiper {
  position: relative;
  overflow: hidden;
}
</style>
