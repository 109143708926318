<!-- -->
<template>
  <div class="frame">
    <div id="banner" class="index-banner">
      <ul class="swiper-wrapper f-cb">
        <li class="swiper-slide" v-for="item in bannerList" :key="item.id">
          <img width="100%" :src="item.sourceUrl" />
        </li>
      </ul>
      <div class="btns"></div>
    </div>
    <div class="index">
      <div id="slide" class="box1">
        <ul class="f-cb swiper-wrapper">
          <li class="swiper-slide" v-for="item in quicklyNav" :key="item.id">
            <router-link :to="item.path">
              <div class="icon">
                <img :src="item.icon" />
              </div>
              <p>{{ item.name }}</p>
            </router-link>
          </li>
          <li class="swiper-slide">
            <a
              href="https://rgek18.kuaishang.cn/bs/im.htm?cas=73088___307759&fi=90873&ism=1"
            >
              <div class="icon">
                <img src="@/assets/image/x1_h.png" />
              </div>
              <p>在线预约</p>
            </a>
          </li>
        </ul>
        <div class="btns"></div>
      </div>
      <div class="mainLine"></div>
      <div
        class="box2"
        style="
          background-image: url('https://www.sclsnk.com/file/mskin/picture/index_bg1.jpg');
        "
      >
        <div class="indexTitle"><span>学科介绍</span></div>
        <div class="boxNew" id="SubjectBox" style="display: block">
          <div class="box-tabs">
            <a
              href="javascript:;"
              :class="{ tab: true, on: activeSubject == item.id }"
              v-for="item in subjectList"
              :key="item.id"
              @click="changeSubject(item.id)"
              >{{ item.officeName }}</a
            >
            <router-link to="/subjectlist_3" class="tab">更多</router-link>
          </div>
          <div class="content box-content">
            <div class="list">
              <div
                class="item hidden"
                v-show="activeSubject == item.id"
                v-for="item in subjectList"
                :key="item.id"
              >
                <div class="tits">{{ item.officeName }}</div>
                <div class="tit f-cb">
                  <div class="pic">
                    <img width="100%" :src="item.imageUrl" />
                  </div>
                  <div class="con">
                    <div class="txt">
                      <p>{{ item.content }}</p>
                    </div>
                    <router-link
                      :to="'/subjectlist_3?type=' + item.id"
                      class="more"
                      >MORE</router-link
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="item1">
              <div class="tits">相关专家</div>
              <div class="tit f-cb">
                <router-link
                  :to="'teamlist_3/' + item.id"
                  v-for="item in aboutDoctor"
                  :key="item.id"
                  >{{ item.name }}</router-link
                >
                <router-link to="/teamlist_4" class="a1"
                  ><span>更多 +</span></router-link
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mainLine"></div>
      <div
        class="box3"
        style="
          background-image: url('https://www.sclsnk.com/file/skin/picture/index_bg2.jpg');
        "
      >
        <div class="indexTitle"><span>专家团队</span></div>
        <div class="content">
          <div class="listBox">
            <div id="team" class="list">
              <ul class="f-cb swiper-wrapper" id="ExpertBox">
                <li
                  class="swiper-slide"
                  style="width: 359px; margin-right: 15px"
                  v-for="item in teamList"
                  :key="item.id"
                >
                  <div class="tits f-cb">
                    <div class="pic">
                      <img width="100%" :src="item.imageHeaderUrl" />
                    </div>
                    <div class="con">
                      <div class="t">
                        <div class="t1">{{ item.name }}</div>
                        <div class="t2">{{ item.position }}</div>
                      </div>
                      <div class="t">
                        <div class="t1">职&nbsp;&nbsp;&nbsp;务</div>
                        <div class="t2">{{ item.duty }}</div>
                      </div>
                      <div class="t">
                        <div class="t1">擅&nbsp;&nbsp;&nbsp;长</div>
                        <div class="t2" style="max-height: auto">
                          {{ item.specializeIn }}
                        </div>
                      </div>
                      <div class="tt">
                        <router-link :to="'teamlist_3/' + item.id"
                          >&lt;专家详情&gt;</router-link
                        >
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
              <div class="btns"></div>
            </div>
          </div>
        </div>
      </div>

      <div class="mainLine"></div>

      <div class="box4">
        <div id="new" class="list">
          <ul class="f-cb swiper-wrapper">
            <li class="swiper-slide" v-for="item in newList" :key="item.id">
              <router-link :to="'/dynamiclist_88/' + item.id">
                <img width="100%" :src="item.newsImageUrl" />
                <div class="tits">
                  <div class="t">{{ item.title }}</div>
                </div>
              </router-link>
            </li>
          </ul>
          <div class="btns"></div>
        </div>
        <div class="tabs">
          <a
            href="javascript:;"
            :class="{ on: activeNewType == 0 }"
            @click="changeNewType(0)"
            >医院新闻</a
          >
          <a
            href="javascript:;"
            :class="{ on: activeNewType == 1 }"
            @click="changeNewType(1)"
            >健康科普</a
          >
          <a
            href="javascript:;"
            :class="{ on: activeNewType == 2 }"
            @click="changeNewType(2)"
            >视频中心</a
          >
        </div>
        <div class="itemBox">
          <div class="item" v-show="activeNewType == 0">
            <ul>
              <li v-for="item in newList" :key="item.id">
                <router-link :to="'/dynamiclist_88/' + item.id">{{
                  item.title
                }}</router-link>
              </li>
            </ul>
            <router-link to="/dynamiclist_88" class="clickMore"
              ><span>查看更多</span></router-link
            >
          </div>
          <div class="item" v-show="activeNewType == 1">
            <ul>
              <li v-for="item in healthList" :key="item.id">
                <router-link :to="'/dynamiclist_5/' + item.id">{{
                  item.title
                }}</router-link>
              </li>
            </ul>
            <router-link to="/dynamiclist_5" class="clickMore"
              ><span>查看更多</span></router-link
            >
          </div>
          <div class="item" v-show="activeNewType == 2">
            <ul>
              <li v-for="item in videoList" :key="item.id">
                <router-link :to="'/dynamiclist_89/' + item.id">{{
                  item.title
                }}</router-link>
              </li>
            </ul>
            <router-link to="/dynamiclist_89" class="clickMore"
              ><span>查看更多</span></router-link
            >
          </div>
        </div>
      </div>
      <div class="mainLine"></div>
      <div
        class="box5"
        style="
          background-image: url('https://www.sclsnk.com/file/mskin/picture/index_bg3.jpg');
        "
      >
        <div class="indexTitle"><span>公益服务</span></div>
        <div class="content">
          <div class="tabs f-cb">
            <a
              href="javascript:;"
              :class="{ on: activeServer == 0 }"
              @click="changeServerType(0)"
              >党建动态</a
            >
            <a
              href="javascript:;"
              :class="{ on: activeServer == 1 }"
              @click="changeServerType(1)"
              >公益活动</a
            >
            <a
              href="javascript:;"
              :class="{ on: activeServer == 2 }"
              @click="changeServerType(2)"
              >医院文化</a
            >
          </div>
          <div class="itemBox">
            <div class="item" v-show="activeServer == 0">
              <ul>
                <li v-for="child in partyList" :key="child.id">
                  <router-link :to="'/culturelist_91/' + child.id">{{
                    child.title
                  }}</router-link>
                </li>
              </ul>
              <router-link to="/culturelist_91" class="clickMore"
                ><span>查看更多</span></router-link
              >
            </div>
            <div class="item" v-show="activeServer == 1">
              <ul>
                <li v-for="child in activityList" :key="child.id">
                  <router-link :to="'/culturelist_92/' + child.id">{{
                    child.title
                  }}</router-link>
                </li>
              </ul>
              <router-link to="/culturelist_92" class="clickMore"
                ><span>查看更多</span></router-link
              >
            </div>
            <div class="item" v-show="activeServer == 2">
              <ul>
                <li v-for="child in memberList" :key="child.id">
                  <router-link :to="'/dynamiclist_112/' + child.id">{{
                    child.title
                  }}</router-link>
                </li>
              </ul>
              <router-link to="/hosculturelist_93" class="clickMore"
                ><span>查看更多</span></router-link
              >
            </div>
          </div>
        </div>
      </div>
      <div class="mainLine"></div>
      <div
        class="box6"
        style="
          background-image: url('https://www.sclsnk.com/file/mskin/picture/index_bg4.jpg');
        "
      >
        <div class="indexTitle"><span>就诊环境</span></div>
        <div class="content">
          <div class="tabs f-cb">
            <a
              href="javascript:;"
              :class="{ on: 0 == activeImgType }"
              @click="changeImgType(0)"
              >医院环境</a
            >
            <a
              href="javascript:;"
              :class="{ on: 1 == activeImgType }"
              @click="changeImgType(1)"
              >先进设备</a
            >
            <a
              href="javascript:;"
              :class="{ on: 2 == activeImgType }"
              @click="changeImgType(2)"
              >医院荣誉</a
            >
          </div>
          <div class="itemBox hidden on">
            <div id="list1" class="item list1" v-show="activeImgType == 0">
              <ul class="f-cb swiper-wrapper" id="PicBox">
                <li
                  class="swiper-slide"
                  v-for="item in environmentList"
                  :key="item.id"
                >
                  <img width="100%" :src="item.newsImageUrl" />
                </li>
              </ul>
              <div class="btns"></div>
            </div>
            <div id="list2" class="item list2" v-show="activeImgType == 1">
              <ul class="f-cb swiper-wrapper" id="PicBox">
                <li
                  class="swiper-slide"
                  v-for="item in equipmentList"
                  :key="item.id"
                >
                  <img width="100%" :src="item.newsImageUrl" />
                </li>
              </ul>
              <div class="btns"></div>
            </div>
            <div id="list3" class="item list3" v-show="activeImgType == 2">
              <ul class="f-cb swiper-wrapper" id="PicBox">
                <li
                  class="swiper-slide"
                  v-for="item in honorList"
                  :key="item.id"
                >
                  <img width="100%" :src="item.newsImageUrl" />
                </li>
              </ul>
              <div class="btns"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="mainLine"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      bannerList: [],
      quicklyNav: [
        {
          id: 1,
          name: "自助挂号",
          icon: require("@/assets/image/x3_h.png"),
          path: "/formAppoint_9",
        },
        {
          id: 2,
          name: "就医流程",
          icon: require("@/assets/image/x6_h.png"),
          path: "/procedure_114",
        },
        {
          id: 3,
          name: "报告查询",
          icon: require("@/assets/image/x5_h.png"),
          path: "/reportSearch_115",
        },
        {
          id: 4,
          name: "门诊排班",
          icon: require("@/assets/image/x4_h.png"),
          path: "/doctorClinic_116",
        },
        {
          id: 5,
          name: "专家门诊",
          icon: require("@/assets/image/x2_h.png"),
          path: "/teamlist_4",
        },
      ],
      subjectList: [], // 学科
      activeSubject: 0,
      aboutDoctor: [], // 相关专家
      teamList: [], // 专家团队
      newList: [], // 医院新闻
      healthList: [], // 健康科普
      videoList: [], // 视频中心
      activeNewType: 0,
      serverList: [
        { id: 30, name: "党建动态", list: [] },
        { id: 50, name: "公益活动", list: [] },
        { id: 33, name: "医院文化", list: [] },
      ],
      partyList: [], // 党建动态
      activityList: [], // 公益互动
      memberList: [], // 员工关怀
      activeServer: 0,
      environmentList: [], // 医院环境
      equipmentList: [], // 先进设备
      honorList: [], // 荣誉
      activeImgType: 0,
    };
  },
  mounted() {
    this.initBanner();
    this.getSubject();
    this.initPro();
    this.getNews();
    this.initSlide();
    this.initTeam();
  },
  methods: {
    initBanner() {
      this.$api.getBannerList().then((res) => {
        this.bannerList = res.data;

        setTimeout(() => {
          new window.Swiper("#banner", {
            loop: true,
            autoplay: true,
            pagination: {
              el: "#banner .btns",
            },
          });
        }, 100);
      });
    },
    // 专家数据
    initPro() {
      const params = {
        pageIndex: 1,
        pageSize: 10,
        isRecommend: 1,
      };
      this.$api.getDoctorList(params).then((res) => {
        this.teamList = res.data.list;
        this.aboutDoctor = this.teamList.slice(0, 5);
      });
    },
    // 快速导航
    initSlide() {
      new window.Swiper("#slide", {
        slidesPerView: 4, // 一页显示数量
        pagination: {
          el: "#slide .btns",
        },
      });
    },
    changeSubject(id) {
      this.activeSubject = id;
    },
    // 新闻中心、健康科普、视频中心、党建动态、公益活动、员工关怀、医院环境、先进设备、医院荣誉
    getNews() {
      const codes = {
        newList: "d6322e9f491845638abd07b6bab593a8",
        healthList: "84f1b1cdc9594e23b97b5ff2c406de15",
        videoList: "e6213e15a9d545b8a363492cacb82a4d",
        partyList: "c8d87756da784bca9fd0fa2a7850fd68",
        activityList: "4fdeb206b7e94c5cae5e568d1844f2a5",
        memberList: "3fcb5f0ebcc944349506a083f301079a",
        environmentList: "846570faab6d4776a162fe222723bffc",
        equipmentList: "fbba0a2f72df4994bff95483b66ce020",
        honorList: "4cc98f10309a414ebcb6ecec5d9bc0c8",
      };
      for (const key in codes) {
        const ele = codes[key];
        const params = {
          pageIndex: 1,
          pageSize: 3,
          code: ele,
        };
        this.$api.getNewsList(params).then((res) => {
          this[key] = res.data.list;

          if (key == "honorList") {
            setTimeout(() => {
              this.initImg();
            }, 100);
          }
          if (key == "newList") {
            setTimeout(() => {
              this.initNew();
            }, 100);
          }
        });
      }
    },
    // 专家团队
    initTeam() {
      new window.Swiper("#team", {
        loop: true,
        autoplay: true,
        pagination: {
          el: "#team .btns",
        },
      });
    },
    // 新闻
    initNew() {
      new window.Swiper("#new", {
        loop: true,
        autoplay: true,
        pagination: {
          el: "#new .btns",
        },
      });
    },
    changeNewType(id) {
      this.activeNewType = id;
    },
    changeServerType(id) {
      this.activeServer = id;
    },
    // 就诊环境
    initImg() {
      new window.Swiper("#list1", {
        loop: true,
        pagination: {
          el: "#list1 .btns",
        },
      });
      new window.Swiper("#list2", {
        loop: true,
        pagination: {
          el: "#list2 .btns",
        },
      });
      new window.Swiper("#list3", {
        loop: true,
        pagination: {
          el: "#list3 .btns",
        },
      });
    },
    changeImgType(id) {
      this.activeImgType = id;
    },
    // 学科
    getSubject() {
      // 五大学科对应的code
      const codes =
        "64e70e4540ac4664a7e3b8806ccafbaa,3a72894919494fccbb363bf12860c14a,63845d908c6a47dea8c4bf5cdeab0e51,8a33c23e5aab434f8890e6162d106563,057597bcb8354312b4556bd68fe1d9a9";
      this.$api.getSubjectByCodes({ codes }).then((res) => {
        this.activeSubject = res.data[0].id;
        this.subjectList = res.data;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.box1 .icon {
  display: flex;
  justify-content: center;
  align-items: center;
}
.box1 .icon img {
  width: 50%;
}
.box-tabs {
  width: 100%;
  background-color: rgb(246, 246, 246);
  padding: 0.35rem 4% 0;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
}
.box-tabs .tab {
  width: 31%;
  height: 0.68rem;
  border-radius: 0.34rem;
  background-color: #fff;
  text-align: center;
  font-size: 0.26rem;
  line-height: 0.68rem;
  color: #666;
  margin-right: 2.3%;
  margin-bottom: 0.23rem;
  display: block;
}
.box-tabs .on {
  background-color: #1068a0;
  color: #fff;
}
.box2 .content .on,
.box6 .content .on {
  display: block;
}

.box6 .btns {
  text-align: center;
}

#banner,
#slide,
#team,
#list1,
#list2,
#list3 {
  overflow: hidden;
  position: relative;
}
</style>
