<!-- -->
<template>
  <div class="frame">
    <Banner />
    <div class="searchPage">
      <div class="w1280">
        <div class="tit">
          搜索结果：“<span class="key">{{ queryParams.name }}</span
          >”
        </div>

        <div class="on" v-if="list.length != 0">
          <ul class="results">
            <li class="f-cb" v-for="item in list" :key="item.id">
              <router-link :to="'/teamlist_4/' + item.id">{{
                item.name
              }}</router-link>
              <span class="date">{{ item.position }}</span>
            </li>
          </ul>
          <el-pagination
            background
            layout="total, prev, pager, next"
            :total="total"
            :current-page="queryParams.pageIndex"
            :page-size="queryParams.pageSize"
            @current-change="currentChange"
          >
          </el-pagination>
        </div>
        <div class="empty" v-else>
          <el-empty description="暂无搜索结果"></el-empty>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Banner from "../components/banner.vue";
export default {
  data() {
    return {
      keyword: "",
      queryParams: {
        pageIndex: 1,
        pageSize: 15,
        name: "",
      },
      total: 0,
      list: [],
    };
  },
  components: { Banner },
  watch: {
    "$route.query.keyword"(val) {
      this.queryParams.name = val;
      this.getList();
    },
  },
  created() {
    this.queryParams.name = this.$route.query.keyword;
    this.getList();
  },
  methods: {
    currentChange(val) {
      this.queryParams.pageIndex = val;
      window.scrollTo({ top: 0 });
      this.getList();
    },
    async getList() {
      let res = await this.$api.getDoctorList(this.queryParams);
      this.list = res.data.list;
      this.total = res.data.total;
    },
  },
};
</script>

<style lang="scss" scoped>
.searchPage .results li a {
  width: auto;
  min-width: 10%;
}
.searchPage .results li .date {
  width: 10%;
}
</style>
