<!-- -->
<template>
  <div class="frame">
    <Banner />
    <BreadCrumb />
    <div class="research">
      <div class="w1280">
        <div class="list f-cb">
          <div class="listNew f-cb" id="datalist">
            <div class="item animation" v-for="item in list" :key="item.id">
              <router-link :to="$route.path + '/' + item.id">
                <div class="pic">
                  <img width="100%" :src="item.newsImageUrl" />
                </div>
                <div class="con">
                  <div class="time">
                    {{ item.publishTime | handleTime(item.createTime) }}
                  </div>
                  <div class="tits">{{ item.title }}</div>
                  <div class="tit">{{ item.description }}</div>
                  <div class="more">MORE ></div>
                </div>
              </router-link>
            </div>
          </div>
          <el-pagination
            background
            layout="total, prev, pager, next"
            :total="total"
            :current-page="queryParams.pageIndex"
            :page-size="queryParams.pageSize"
            @current-change="currentChange"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Banner from "../components/banner.vue";
import BreadCrumb from "../components/breadCrumb.vue";
export default {
  data() {
    return {
      queryParams: {
        pageIndex: 1,
        pageSize: 12,
        dictIds: "",
      },
      list: [],
      total: 0,
    };
  },
  components: { Banner, BreadCrumb },
  created() {
    this.queryParams.dictIds = this.$route.params.id;
    this.getList();
  },
  methods: {
    currentChange(val) {
      this.queryParams.pageIndex = val;
      window.scrollTo({ top: 0 });
      this.getList();
    },
    getList() {
      this.$api.getNewsList(this.queryParams).then((res) => {
        this.list = res.data.list;
        this.total = res.data.total;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.list .f-cb {
  display: flex;
  flex-wrap: wrap;
}
</style>
