<template>
  <div class="main">
    <Top />
    <el-backtop :visibility-height="400" @click="backTotop"></el-backtop>
    <keep-alive>
      <router-view
        :key="$route.path"
        v-if="$route.meta.keepAlive"
      ></router-view>
    </keep-alive>
    <router-view :key="$route.path" v-if="!$route.meta.keepAlive"></router-view>
    <Bottom />
  </div>
</template>

<script>
import Top from "./components/top.vue";
import Bottom from "./components/bottom.vue";

export default {
  data() {
    return {};
  },
  components: {
    Top,
    Bottom,
  },
  methods: {
    backTotop() {
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 1200px) {
  .el-backtop {
    bottom: 1.8rem !important;
  }
}
</style>
