<!-- -->
<template>
  <div class="frame">
    <BreadCrumb />
    <Banner />
    <Nav />
    <div class="content">
      <div class="detail">
        <p>
          <img
            src="https://www.sclsnk.com/static/upload/image/20240827/1724721838763940.jpg"
            title="1724721838763940.jpg"
            alt="da3fe6a15f8e6026f37f11e4b7ffeeb.jpg"
          />
        </p>
        <p>
          <img
            src="https://www.sclsnk.com/static/upload/image/20240827/1724721845727879.jpg"
            title="1724721845727879.jpg"
            alt="73d12cfe4fad03cfd3e0c67a752e554.jpg"
          />
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Banner from "@/components/mBanner.vue";
import BreadCrumb from "@/components/breadCrumb.vue";
import Nav from "@/components/mNav.vue";
import { Store } from "@/store/index.js";
import { mapState } from "pinia";
export default {
  data() {
    return {
      list: [],
    };
  },
  components: { Banner, BreadCrumb, Nav },
  computed: {
    ...mapState(Store, ["globalConfig"]),
  },
};
</script>

<style lang="scss" scoped>
.content {
  width: 100%;
}
.content .detail {
  padding: 10px 10px;
  box-sizing: border-box;
}
</style>
