<!-- -->
<template>
  <div class="frame">
    <BreadCrumb />
    <Banner />
    <Nav />
    <div class="WeChat">
      <div class="box1">
        <div class="title">“四川蓝生脑科医院”微信公众号预约</div>
        <div class="con f-cb">
          <div class="conNew">
            1 、关注微信公众号“四川蓝生脑科医院“<br />
            2 、选择“咨询预约”中的“自助预约挂号”<br />
            3 、选择“挂号”，选择医师<br />
            4 、选择科室、就诊日期／医生<br />
            5
            、确认信息（就诊人、就诊时间等），无误后确认挂号，完成支付．预约成功。<br />
            6
            、看诊当日微信公众号订单内取号（自助机、人工窗口凭电子健康卡／诊疗卡取号）<br />
            7 、取号后相应科室就诊
          </div>
          <div class="ewm">
            <img
              :src="globalConfig.hospitalServiceNumber"
              style="width: 100%"
            />
          </div>
        </div>
        <div class="con1">
          <div class="tits">具体步骤</div>

          <div class="pic">
            <img
              src="https://www.sclsnk.com/file/mskin/picture/WeChat.jpg"
              style="width: 100%"
            />
          </div>
        </div>
      </div>
      <div class="box2">
        <div class="boxNew">
          <div class="tits">温馨提示</div>
          <div class="tit">
            1
            、预约挂号为免费服务，请使用患者真实信思进行实名制预约；仅支持护照、军官证、港澳通行证、身份证预约；<br />
            2
            、请预留准确的联系电话，以便因临时出诊信息变动，我们可以及时通知到您；<br />
            3
            、预约成功后因故需取消原预约信息的，请于就诊前一个工作日15点之前于预约订单内取消；<br />
            4 、按预约时间提前30分诊前来就诊；<br />
            5
            、上午看诊后需下午看结果者，请在自助机或者人工窗口领取免费复诊号；隔日复诊请挂号就诊；<br />
            6 、我院为就诊患者免费提供病历本，无需购买；<br />
            7 、当日挂号后可直接就诊。预约挂号需取号后就诊。
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Banner from "@/components/mBanner.vue";
import BreadCrumb from "@/components/breadCrumb.vue";
import Nav from "../../components/mNav.vue";
import { Store } from "@/store/index.js";
import { mapState } from "pinia";
export default {
  data() {
    return {};
  },
  components: { Banner, BreadCrumb, Nav },
  computed: {
    ...mapState(Store, ["globalConfig"]),
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.in {
  margin: 60px 0 80px;
}
.in .way {
  font-size: 18px;
  text-align: center;
  margin-top: 40px;
}
.way p {
  margin-bottom: 10px;
}
.imgs {
  display: flex;
  justify-content: center;
}
.img + .img {
  margin-left: 30px;
}
.img img {
  width: 170px;
  height: 170px;
}
.img p {
  text-align: center;
  margin-top: 5px;
  font-size: 16px;
}
</style>
