<!-- -->
<template>
  <div class="frame">
    <BreadCrumb />
    <Banner />
    <Nav />
    <div class="environment">
      <div class="list">
        <ul class="f-cb" id="datalist">
          <li v-for="item in list" :key="item.title">
            <div class="pic">
              <img width="100%" :src="item.newsImageUrl" />
            </div>
            <div class="tits">{{ item.title }}</div>
          </li>
        </ul>
        <a
          href="javascript:;"
          class="moreClick"
          @click="getMore"
          v-show="hasMore"
          ><span>查看更多</span></a
        >
      </div>
    </div>
    <div class="mainLine"></div>
  </div>
</template>

<script>
import Banner from "@/components/mBanner.vue";
import BreadCrumb from "@/components/breadCrumb.vue";
import Nav from "../../components/mNav.vue";
export default {
  data() {
    return {
      queryParams: {
        pageIndex: 1,
        pageSize: 10,
        dictIds: "106",
      },
      list: [],
      hasMore: true,
    };
  },
  components: { Banner, BreadCrumb, Nav },
  created() {
    this.getList();
  },
  methods: {
    getMore() {
      this.queryParams.pageIndex++;
      this.getList("more");
    },
    getList(type) {
      this.$api.getNewsList(this.queryParams).then((res) => {
        if (type == "more") {
          this.list = this.list.concat(res.data.list);
        } else {
          this.list = res.data.list;
        }
        if (this.queryParams.pageIndex >= res.data.totalPages) {
          this.hasMore = false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#swiper {
  position: relative;
  overflow: hidden;
}
</style>
