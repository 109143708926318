<!-- -->
<template>
  <div class="frame">
    <Banner />
    <BreadCrumb />
    <div class="expertInfo">
      <div class="box1 w1280 f-cb">
        <div class="content f-cb">
          <div class="pic animation">
            <img width="100%" :src="detail.imageHeaderUrl" />
          </div>
          <div class="con animation">
            <div class="tits">{{ detail.name }}</div>
            <div class="tit">{{ detail.position }}</div>
            <div class="tit1">
              <div class="t f-cb">
                <span>科&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;室：</span>
                <i>{{ detail.administrativeOffice }}</i>
              </div>
              <div class="t f-cb">
                <span>职&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;务：</span>
                <i>{{ detail.duty }}</i>
              </div>
              <div class="t f-cb">
                <span>专业擅长：</span>
                <i>{{ detail.specializeIn }}</i>
              </div>
              <div class="t f-cb">
                <span>专家介绍：</span>
                <i v-html="detail.expertIntroduction"></i>
              </div>
            </div>
            <div class="tit2 f-cb">
              <a rel="nofollow" :href="globalConfig.zixun">
                <span class="btn">在线预约</span>
              </a>
            </div>
          </div>
        </div>
        <div
          class="
            list
            swiper-container
            animation
            swiper-container-initialized swiper-container-horizontal
            srcospcur
          "
        >
          <ul class="swiper-wrapper" id="calendar">
            <li
              class="swiper-slide swiper-slide-active"
              style="width: 1280px"
              v-html="detail.schedulingChartImageUrl"
            ></li>
          </ul>
          <a
            href="javascript:;"
            class="a prev swiper-button-disabled"
            tabindex="0"
            role="button"
            aria-label="Previous slide"
            aria-disabled="true"
          ></a>
          <a
            href="javascript:;"
            class="a next"
            tabindex="0"
            role="button"
            aria-label="Next slide"
            aria-disabled="false"
          ></a>
          <span
            class="swiper-notification"
            aria-live="assertive"
            aria-atomic="true"
          ></span>
        </div>
        <div class="list swiper-container animation">
          <ul class="swiper-wrapper" id="calendar"></ul>
          <a href="javascript:;" class="a prev"></a>
          <a href="javascript:;" class="a next"></a>
        </div>
      </div>
    </div>
    <!-- 底部 END -->
    <Side />
  </div>
</template>

<script>
import Banner from "../components/banner.vue";
import BreadCrumb from "../components/breadCrumb.vue";
import Side from "../components/side.vue";
import { Store } from "@/store/index.js";
import { mapState } from "pinia";
export default {
  data() {
    return {
      detail: {},
    };
  },
  computed: {
    ...mapState(Store, ["globalConfig"]),
  },
  components: { Banner, BreadCrumb, Side },
  created() {
    this.$api.getDoctorDetail({ id: this.$route.params.child }).then((res) => {
      this.detail = res.data;
    });
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.btn {
  background-image: url("https://www.sclsnk.com/file/skin/picture/expertInfo_icon2.png");
}
</style>
