<!-- -->
<template>
  <div class="frame">
    <BreadCrumb />
    <div class="expertInfo">
      <div class="box1 bgc f-cb">
        <div class="pic">
          <img width="100%" :src="detail.imageHeaderUrl" />
        </div>
        <div class="con">
          <div class="tits f-cb">
            <i>{{ detail.name }}</i
            ><span>{{ detail.position }}</span>
          </div>
          <div class="tit">
            <div class="t">科室：{{ detail.administrativeOffice }}</div>
            <div class="t">职务：{{ detail.duty }}</div>
          </div>
        </div>
      </div>
      <div class="mainLine"></div>
      <div class="box3">
        <div class="tabs">
          <div class="tabsNew">
            <a
              :class="{ on: activeTab == 0 }"
              href="javascript:;"
              @click="changeTab(0)"
              >专家介绍</a
            >
            <a
              :class="{ on: activeTab == 1 }"
              href="javascript:;"
              @click="changeTab(1)"
              >擅长疾病</a
            >
          </div>
        </div>
        <div class="conBox">
          <div
            class="con"
            :style="{ display: activeTab == 0 ? 'block' : 'none' }"
          >
            <p v-html="detail.expertIntroduction"></p>
            <p>&nbsp;</p>
          </div>

          <div
            class="con"
            :style="{ display: activeTab == 1 ? 'block' : 'none' }"
          >
            <p>{{ detail.specializeIn }}</p>
            <p>&nbsp;</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BreadCrumb from "@/components/breadCrumb.vue";
export default {
  data() {
    return {
      activeTab: 0,
      detail: {},
    };
  },
  components: { BreadCrumb },
  created() {
    this.$api.getDoctorDetail({ id: this.$route.params.child }).then((res) => {
      this.detail = res.data;
    });
  },
  methods: {
    changeTab(idx) {
      this.activeTab = idx;
    },
  },
};
</script>

<style lang="scss" scoped>
.box1 {
  background-image: url("https://www.sclsnk.com/file/mskin/picture/expert_bg.jpg");
}
</style>
