<!-- -->
<template>
  <div class="banner">
    <img width="100%" src="@/assets/image/about_banner.jpg" />
    <div class="con">
      <div class="cn">{{ topNav.name }}</div>
      <div class="en">Introduction</div>
    </div>
  </div>
</template>

<script>
import { Store } from "@/store/index.js";
import { mapState } from "pinia";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapState(Store, ["navList"]),
    // 扁平化导航条
    nav() {
      return this.flatNav.find((ele) => ele.id == this.$route.params.id);
    },
    // 扁平化导航条
    flatNav() {
      return this.navList.flatMap((ele) => [ele, ...ele.children]);
    },
    topNav() {
      const first = this.flatNav.find((ele) => ele.id == this.$route.params.id);
      if (!first) return [];
      // 若为二级字典，则反找一级字典的children
      if (first.level == 2) {
        return this.navList.find((ele) => ele.code == first.parentCode);
      }
      return first;
    },
  },
  methods: {},
};
</script>