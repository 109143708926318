<!-- -->
<template>
  <div class="frame">
    <div class="expert">
      <div class="searchBox f-cb">
        <div class="sel">
          <div :class="{ tits: true, on: isExpand }" @click="expandType">
            <span>按科室分<i></i></span>
          </div>
          <div
            class="selNew item1"
            :style="{ display: isExpand ? 'block' : 'none' }"
          >
            <div class="box f-cb">
              <div class="tabs">
                <a
                  href="javascript:;"
                  style="padding: 0 0.2rem"
                  :class="{ on: activeSubject.officeName == item.officeName }"
                  v-for="item in subjectList"
                  :key="item.id"
                  @click="changeHouse(item.id)"
                  >{{ item.officeName }}</a
                >
              </div>

              <div class="conBox">
                <ul style="display: block">
                  <li
                    :class="{
                      on: queryParams.administrativeOffice == item.officeName,
                    }"
                    v-for="item in activeSubject.children"
                    :key="item.id"
                    @click="selectOffice(item)"
                  >
                    <a href="javascript:;">{{ item.officeName }}</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <a href="javascript:;" class="searchClick">
          <div class="sear">
            <input
              type="search"
              class="text"
              placeholder="输入专家姓名搜索"
              name=""
            />
          </div>
        </a>
      </div>
      <div class="list">
        <ul id="datalist">
          <li>
            <router-link
              :to="$route.path + '/' + item.id"
              class="f-cb"
              v-for="item in list"
              :key="item.id"
            >
              <div class="pic">
                <img width="100%" :src="item.imageHeaderUrl" />
              </div>
              <div class="con">
                <div class="tits f-cb">
                  <i>{{ item.name }}</i>
                  <span class="s1">{{ item.position }}</span>
                </div>
                <div class="tit">
                  <div class="t f-cb">
                    <span>所属科室：</span>
                    <i>{{ item.administrativeOffice }}</i>
                  </div>
                  <div class="t f-cb">
                    <span>擅长疾病：</span>
                    <i>{{ item.specializeIn }}</i>
                  </div>
                </div>
              </div>
            </router-link>
          </li>
        </ul>
        <a
          href="javascript:;"
          class="moreClick"
          @click="getMore"
          v-show="hasMore"
          ><span>查看更多</span></a
        >
      </div>
    </div>
  </div>
</template>

<script>
import { Store } from "@/store/index.js";
import { mapState } from "pinia";
export default {
  data() {
    return {
      isExpand: false,
      activeSubject: {
        name: "",
        children: [],
      },
      activeType: "",
      queryParams: {
        pageIndex: 1,
        pageSize: 15,
        administrativeOffice: "",
      },
      list: [],
      hasMore: true,
    };
  },
  computed: {
    ...mapState(Store, ["subjectList"]),
  },
  created() {
    this.getList();
  },
  methods: {
    expandType() {
      this.isExpand = !this.isExpand;
    },
    // 改变科室
    changeHouse(id) {
      const subject = this.subjectList.find((ele) => ele.id == id);
      this.activeSubject = subject || {
        name: "",
        children: [],
      };
    },
    // 选择科室
    selectOffice(item) {
      this.isExpand = false;
      this.queryParams.administrativeOffice = item.officeName;
      this.queryParams.pageIndex = 1;
      this.getList();
    },
    startSearch() {
      this.queryParams.pageIndex = 1;
      this.getList();
    },
    getMore() {
      this.queryParams.pageIndex++;
      this.getList("more");
    },
    async getList(type) {
      this.hasMore = true;
      let res = await this.$api.getDoctorList(this.queryParams);
      if (type == "more") {
        this.list = this.list.concat(res.data.list);
      } else {
        this.list = res.data.list;
      }
      if (this.queryParams.pageIndex >= res.data.totalPages) {
        this.hasMore = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.bottom {
  display: none;
}
.box .conBox .on a {
  color: #1f5aa1 !important;
  font-weight: bold;
}
</style>
