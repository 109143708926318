<!-- -->
<template>
  <div class="frame">
    <Banner />
    <BreadCrumb />
    <div class="contain">
      <div class="w1280">
        <div class="in">
          <div class="imgs">
            <div class="img">
              <img :src="globalConfig.hospitalSubscriptionNumber" />
              <p>医院订阅号</p>
            </div>
            <div class="img">
              <img :src="globalConfig.hospitalServiceNumber" />
              <p>医院服务号</p>
            </div>
            <div class="img">
              <img :src="globalConfig.hospitalVideoNumber" />
              <p>医院视频号</p>
            </div>
          </div>
          <div class="way">
            <p>联系电话：{{ globalConfig.hospitalPhoneNo }}</p>
            <p>急诊电话：028-67055856</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Banner from "../components/banner.vue";
import BreadCrumb from "../components/breadCrumb.vue";
import { Store } from "@/store/index.js";
import { mapState } from "pinia";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapState(Store, ["globalConfig"]),
  },
  components: { Banner, BreadCrumb },
  methods: {},
};
</script>

<style lang="scss" scoped>
.in {
  margin: 60px 0 80px;
}
.in .way {
  font-size: 18px;
  text-align: center;
  margin-top: 40px;
}
.way p {
  margin-bottom: 10px;
}
.imgs {
  display: flex;
  justify-content: center;
}
.img + .img {
  margin-left: 30px;
}
.img img {
  width: 170px;
  height: 170px;
}
.img p {
  text-align: center;
  margin-top: 5px;
  font-size: 16px;
}
</style>
