<!-- -->
<template>
  <div class="frame">
    <Banner />
    <BreadCrumb />
    <div class="about">
      <div class="box1">
        <div class="w1280 f-cb">
          <div class="temp_001">
            <div class="content f-cb">
              <div class="con animation">
                <div class="tits">{{ globalConfig.name }}</div>
                <div class="tit" v-html="aboutNew.contentStr"></div>
              </div>
              <div class="videoClick animation">
                <img width="100%" :src="aboutNew.newsImageUrl" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="box2">
        <div class="w1280">
          <div class="indexTitle animation">
            <div class="tits">重点学科</div>
            <div class="line"></div>
          </div>
          <div class="list animation">
            <ul>
              <li
                class="f-cb"
                :style="{
                  display: idx == activeSubject ? 'list-item' : 'none',
                }"
                v-for="(item, idx) in subjectList"
                :key="item.id"
              >
                <div class="pic">
                  <img width="100%" :src="item.importantImageUrl" />
                </div>
                <div class="con">
                  <div class="conNew">
                    <div class="tits">{{ item.officeName }}</div>
                    <div class="tit">
                      {{ item.importantDesc }}
                    </div>
                    <div style="margin-top: 5px">
                      <router-link
                        :to="'/subjectlist_3?type=' + item.id"
                        class="more"
                        >>> 点击详情</router-link
                      >
                    </div>
                  </div>
                </div>
              </li>
            </ul>
            <div class="tabs animation">
              <a
                :class="{ on: idx == activeSubject }"
                v-for="(item, idx) in subjectList"
                :key="item.id"
                @mouseenter="changeSubject(idx)"
              >
                <div class="line"></div>
                <div class="t">{{ item.officeName }}</div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="box4">
        <div class="w1280">
          <div class="indexTitle animation">
            <div class="tits">医院资质</div>
            <div class="line"></div>
          </div>
          <div class="list animation">
            <div
              class="item"
              :title="item.title"
              v-for="item in certification"
              :key="item.id"
            >
              <div class="pic">
                <img width="100%" :src="item.newsImageUrl" />
              </div>
              <div class="tits">{{ item.title }}</div>
            </div>
          </div>
          <el-pagination
            background
            layout="total, prev, pager, next"
            :total="totalCert"
            :current-page="certQueryParams.pageIndex"
            :page-size="certQueryParams.pageSize"
            @current-change="currentChange"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Banner from "../components/banner.vue";
import BreadCrumb from "../components/breadCrumb.vue";
import { Store } from "@/store/index.js";
import { mapState } from "pinia";
export default {
  data() {
    return {
      aboutNew: {},
      subjectList: [],
      activeSubject: 0,
      certQueryParams: {
        pageIndex: 1,
        pageSize: 12,
        code: "10aa8e5f6cdd42fd95e5cc18cc1f3191",
      },
      certification: [],
      totalCert: 0,
    };
  },
  components: { Banner, BreadCrumb },
  computed: {
    ...mapState(Store, ["globalConfig"]),
  },
  created() {
    this.getIntro();
    this.getList();
    this.getCertification();
  },
  methods: {
    getIntro() {
      this.$api.getNewDetail("1").then((res) => {
        this.aboutNew = res.data;
      });
    },
    getList() {
      const params = {
        pageIndex: 1,
        pageSize: 50,
        importantFlag: 1,
      };
      this.$api.getSubjectList(params).then((res) => {
        this.subjectList = res.data.list;
      });
    },
    getCertification() {
      this.$api.getNewsList(this.certQueryParams).then((res) => {
        this.certification = res.data.list;
        this.totalCert = res.data.total;
      });
    },
    changeSubject(idx) {
      this.activeSubject = idx;
    },
    currentChange(val) {
      this.certQueryParams.pageIndex = val;
      this.getCertification();
    },
  },
};
</script>

<style lang="scss" scoped>
.box2 .list ul li .con .conNew .tit {
  text-overflow: ellipsis;
  -webkit-line-clamp: 9;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.box2 .conNew .more {
  font-size: 14px;
  color: #e5e5e5;
  cursor: pointer;
}
.box2 .conNew .more:hover {
  text-decoration: underline;
}
.box4 {
  width: 100%;
  height: auto;
  background-color: #f1f2f1;
  padding-top: 40px;
  padding-bottom: 80px;
}
.box4 .list {
  margin: 30px 0 10px;
  display: flex;
  flex-wrap: wrap;
}
.box4 .list .item {
  width: 25%;
  margin-bottom: 48px;
  padding: 0 10px;
  box-sizing: border-box;
}
</style>
