<template>
  <footer class="footerBox">
    <div class="footer">
      <div class="w1280 f-cb">
        <div class="conLeft">
          <div class="logo">
            <img width="100%" :src="globalConfig.logoImgUrl" />
          </div>
          <div class="ewmBox">
            <div class="ewm">
              <img
                width="100%"
                :src="globalConfig.hospitalSubscriptionNumber"
              />
              <p>官方订阅号</p>
            </div>
            <div class="ewm">
              <img width="100%" :src="globalConfig.hospitalServiceNumber" />
              <p>微信公众号</p>
            </div>
          </div>
          <div class="share" style="display: none">
            <a
              href="javascript:;"
              style="
                background-image: url('https://www.sclsnk.com/file/skin/picture/wx.png');
              "
            ></a>
            <a
              href="javascript:;"
              style="
                background-image: url('https://www.sclsnk.com/file/skin/picture/qq.png');
              "
            ></a>
            <a
              href="javascript:;"
              style="
                background-image: url('https://www.sclsnk.com/file/skin/picture/wx.png');
              "
            ></a>
          </div>
        </div>
        <div class="conRight">
          <div class="top f-cb">
            <div class="item">
              <div class="tits">联系我们</div>
              <div class="tit">
                <div class="t f-cb">
                  <span>医院电话</span><em></em
                  ><i>{{ globalConfig.hospitalPhoneNo }}</i>
                </div>
                <div class="t f-cb">
                  <span>门诊时间</span><em></em><i>8:00-17:00（节假日不休）</i>
                </div>
                <div class="t f-cb">
                  <span>急诊时间</span><em></em
                  ><i>00：00-24：00（节假日不休）</i>
                </div>
                <div class="t f-cb">
                  <span>咨询时间</span><em></em
                  ><i>7：00-22：30（节假日不休）</i>
                </div>
              </div>

              <div class="tits"><br />行风廉政建设举报</div>
              <div class="tit">
                <div class="t f-cb">
                  <span>电 话</span><em></em><i>028-6705 5866</i>
                </div>
                <div class="t f-cb">
                  <span>邮 箱</span><em></em><i>{{ globalConfig.email }}</i>
                </div>
              </div>
            </div>
            <div class="item item1">
              <div class="tits">交通路线</div>
              <div class="tit">
                <div class="t f-cb">
                  <span>医院地址</span><em></em
                  ><i>{{ globalConfig.companyAddress }}</i>
                </div>
                <div class="t t1 f-cb">
                  <span>地铁线路</span><em></em
                  ><i>{{ globalConfig.subwayLine }}</i>
                </div>
                <div class="t t1 f-cb">
                  <span>公交线路</span><em></em
                  ><i>{{ globalConfig.transportLine }}</i>
                </div>
                <div class="t t1 f-cb">
                  <span>公交线路</span><em></em
                  ><i>{{ globalConfig.transportLine2 }}</i>
                </div>
              </div>
            </div>
          </div>
          <div class="list">
            <ul>
              <li v-for="item in topList" :key="item.name">
                <router-link :to="item.templateDesc + '_' + item.id">{{
                  item.name
                }}</router-link>
              </li>
              <li>
                <router-link to="/procedure_114">就医流程</router-link>
              </li>
              <li>
                <router-link to="/message_117">投诉举报</router-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="f-cb"></div>
        <div class="nav" style="display: none">
          <a href="">法律责任</a>
          <a href="">意见建议</a>
          <a href="">网站纠错</a>
        </div>
      </div>
    </div>
    <div class="foot">
      <div class="w1280 f-cb">
        <div class="t" style="float: none; text-align: center">
          <a
            href="https://beian.miit.gov.cn/"
            target="_blank"
            style="color: #fff"
            >{{ globalConfig.icpMsg }}</a
          >
        </div>
        <div class="link js-noclick" style="display: none">
          <div class="tits">友情链接</div>
          <ul id="content">
            <li><a href="#" target="_blank">{pboot:companyname}</a></li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
import { Store } from "@/store/index.js";
import { mapState } from "pinia";
import { URL } from "@/util/config.js";
export default {
  data() {
    return {
      URL: URL,
    };
  },
  computed: {
    ...mapState(Store, ["globalConfig"]),
    topList() {
      return this.$store.navList.slice(0, 7);
    },
  },
  mounted() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.footer {
  width: 100%;
}
</style>
