<!-- -->
<template>
  <div class="frame">
    <BreadCrumb />
    <Banner />
    <Nav />
    <div class="contain">
      <div class="navs">
        <router-link
          :to="item.templateDesc + '_' + item.id"
          :class="{
            nav: true,
            on: item.templateDesc + '_' + item.id == $route.path,
          }"
          v-for="item in subList"
          :key="item.name"
          >{{ item.name }}</router-link
        >
      </div>
      <div class="list f-cb">
        <div class="news">
          <ul id="datalist">
            <li v-for="item in list" :key="item.id">
              <router-link
                :to="'/dynamiclist_' + item.dictId + '/' + item.id"
                class="f-cb"
              >
                <div class="con">
                  <div class="tits">{{ item.title }}</div>
                  <div class="time">
                    {{ item.publishTime | handleTime(item.createTime) }}
                  </div>
                </div>
                <div class="pic">
                  <img width="100%" :src="item.newsImageUrl" />
                </div>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Banner from "@/components/mBanner.vue";
import BreadCrumb from "@/components/breadCrumb.vue";
import Nav from "../../components/mNav.vue";
export default {
  data() {
    return {
      subList: [],
      list: [],
    };
  },
  components: { Banner, BreadCrumb, Nav },
  created() {
    this.getNavList();
    this.getList();
  },
  methods: {
    getNavList() {
      this.$api
        .getDicByParentCode({ code: "0c4997cc6d6244b989f0ad98e0779b5b" })
        .then((res) => {
          this.subList = res.data[0].children;
        });
    },
    getList() {
      const params = {
        pageIndex: 1,
        pageSize: 50,
        dictIds: this.$route.params.id,
      };
      this.$api.getNewsList(params).then((res) => {
        this.list = res.data.list;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.contain .navs {
  width: fit-content;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 10px auto 20px;
  border: 1px solid #16559c;
  border-radius: 20px;
  overflow: hidden;
}
.navs .nav {
  width: 70px;
  text-align: center;
  color: #333;
  padding: 5px 0;
}
.navs .nav + .nav {
  border-left: 1px solid #16559c;
}
.navs .on {
  color: #fff;
  font-weight: bold;
  background-color: #16559c;
}
</style>
