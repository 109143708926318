import { render, staticRenderFns } from "./hosCulture.vue?vue&type=template&id=059b244f&scoped=true&"
import script from "./hosCulture.vue?vue&type=script&lang=js&"
export * from "./hosCulture.vue?vue&type=script&lang=js&"
import style0 from "./hosCulture.vue?vue&type=style&index=0&id=059b244f&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "059b244f",
  null
  
)

export default component.exports