<!-- -->
<template>
  <div class="frame">
    <BreadCrumb />
    <Banner />
    <Nav />
    <div class="contact">
      <ul id="datalist">
        <li v-for="item in list" :key="item.id">
          <div
            :class="{ tits: true, on: item.expand }"
            @click="changeAcitve(item)"
          >
            <div class="t">{{ item.jobName }}</div>
            <div class="t1">{{ item.jobTypeName }}</div>
            <i></i>
          </div>
          <div class="con" v-html="item.content" v-show="item.expand"></div>
        </li>
      </ul>
      <!-- <a href="javascript:;" class="moreClick"><span>查看更多</span></a> -->
    </div>
  </div>
</template>

<script>
import Banner from "@/components/mBanner.vue";
import BreadCrumb from "@/components/breadCrumb.vue";
import Nav from "../../components/mNav.vue";
export default {
  data() {
    return {
      active: undefined,
      list: [],
    };
  },
  components: { Banner, BreadCrumb, Nav },
  created() {
    this.getType();
  },
  methods: {
    getType() {
      this.$api.getAllJob().then((res) => {
        this.list = res.data.map((ele) => {
          ele.expand = false;
          return ele;
        });
      });
    },
    changeAcitve(item) {
      // 收起
      if (item.expand) {
        item.expand = false;
        return;
      }
      // 展开
      this.list.forEach((ele) => {
        ele.expand = false;
      });
      item.expand = true;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
