<!-- -->
<template>
  <div class="frame">
    <BreadCrumb />
    <Banner />
    <Nav />
    <div class="traffic">
      <div class="map" id="map">
        <div id="all2map"></div>
      </div>
      <div class="content">
        <div class="tabs animation">
          <a
            href="javascript:;"
            :class="{ on: active == 0 }"
            @click="changeWay(0)"
            >地铁轨道交通路线</a
          >
          <a
            href="javascript:;"
            :class="{ on: active == 1 }"
            @click="changeWay(1)"
            >公交车路线</a
          >
        </div>
        <div class="itemBox animation">
          <div
            class="item"
            :style="{ display: active == 0 ? 'block' : 'none' }"
          >
            <div class="t">
              <span>地铁7号线</span><br />
              {{ globalConfig.subwayLine }}
            </div>
          </div>
          <div
            class="item"
            :style="{ display: active == 1 ? 'block' : 'none' }"
          >
            <div class="t">
              <span>公交线路1</span><br />
              {{ globalConfig.transportLine }}
            </div>
            <div class="t">
              <span>公交路线2</span><br />
              {{ globalConfig.transportLine2 }}
            </div>
          </div>
        </div>
      </div>
      <div class="mainLine"></div>
    </div>
  </div>
</template>

<script>
import Banner from "@/components/mBanner.vue";
import BreadCrumb from "@/components/breadCrumb.vue";
import Nav from "../../components/mNav.vue";
import { Store } from "@/store/index.js";
import { mapState } from "pinia";
export default {
  data() {
    return {
      active: 0,
    };
  },
  components: { Banner, BreadCrumb, Nav },
  computed: {
    ...mapState(Store, ["globalConfig"]),
  },
  mounted() {
    if (this.globalConfig.mapCoordinates) {
      this.initMap();
    }
  },
  methods: {
    changeWay(idx) {
      this.active = idx;
    },
    initMap() {
      const path = this.globalConfig.mapCoordinates
        .split(",")
        .map((ele) => Number(ele)); // 数据处理
      let AMap = window.AMap;
      var map = new AMap.Map("all2map", {
        zoom: 16, //地图级别
        center: path, //地图中心点
      });
      AMap.plugin(
        ["AMap.ToolBar", "AMap.Scale", "AMap.Geolocation", "AMap.ControlBar"],
        function () {
          //添加工具条控件，工具条控件集成了缩放、平移、定位等功能按钮在内的组合控件
          map.addControl(new AMap.ToolBar());

          //添加比例尺控件，展示地图在当前层级和纬度下的比例尺
          map.addControl(new AMap.Scale());

          //添加定位控件，用来获取和展示用户主机所在的经纬度位置
          map.addControl(new AMap.Geolocation());

          //添加控制罗盘控件，用来控制地图的旋转和倾斜
          map.addControl(new AMap.ControlBar());
        }
      );
      var marker = new AMap.Marker({
        position: new AMap.LngLat(...path), //经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
        title: this.globalConfig.name,
      });
      map.add(marker);
    },
  },
};
</script>

<style lang="scss" scoped>
#all2map {
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-family: "微软雅黑";
  font-size: 14px;
}

#all2map b {
  color: #cc5522;
  font-size: 14px;
}

#all2map img {
  max-width: none;
}
</style>
