<!-- -->
<template>
  <div class="frame">
    <BreadCrumb />
    <Banner />
    <Nav />
    <div class="news">
      <ul id="datalist">
        <li v-for="item in list" :key="item.id">
          <router-link :to="$route.path + '/' + item.id" class="f-cb">
            <div class="con">
              <div class="tits">{{ item.title }}</div>
              <div class="time">
                {{ item.publishTime | handleTime(item.createTime) }}
              </div>
            </div>
            <div class="pic">
              <img width="100%" :src="item.newsImageUrl" />
            </div>
          </router-link>
        </li>
      </ul>
    </div>
    <div class="mainLine"></div>
    <a href="javascript:;" class="moreClick" @click="getMore" v-show="hasMore"
      ><span>查看更多</span></a
    >
    <div class="mainLine"></div>
  </div>
</template>

<script>
import Banner from "@/components/mBanner.vue";
import BreadCrumb from "@/components/breadCrumb.vue";
import Nav from "../../components/mNav.vue";
export default {
  data() {
    return {
      queryParams: {
        pageIndex: 1,
        pageSize: 15,
        dictIds: "",
      },
      total: 0,
      list: [],
      hotList: [],
      hasMore: true,
    };
  },
  components: { Banner, BreadCrumb, Nav },
  created() {
    this.queryParams.dictIds = this.$route.params.id;
    this.getList();
  },
  mounted() {},
  methods: {
    getMore() {
      this.queryParams.pageIndex++;
      this.getList("more");
    },
    async getList(type) {
      this.hasMore = true;
      let res = await this.$api.getNewsList(this.queryParams);
      if (type == "more") {
        this.list = this.list.concat(res.data.list);
      } else {
        this.list = res.data.list;
      }
      if (this.queryParams.pageIndex >= res.data.totalPages) {
        this.hasMore = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#swiper {
  height: 400px;
  position: relative;
  overflow: hidden;
}
</style>
