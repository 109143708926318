<!-- -->
<template>
  <div class="frame">
    <BreadCrumb />
    <Banner />
    <Nav />
    <div class="layout">
      <p>
        <img
          src="https://www.sclsnk.com/file/mskin/picture/process.jpg"
          width="100%"
        />
      </p>
    </div>
  </div>
</template>

<script>
import Banner from "@/components/mBanner.vue";
import BreadCrumb from "@/components/breadCrumb.vue";
import Nav from "@/components/mNav.vue";
export default {
  data() {
    return {};
  },
  components: { Banner, BreadCrumb, Nav },
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>
