import request from "./request";

export default {
  // 查询首页导航条
  getNavDic(params) {
    return request({
      url: `web/sys-dictionary/front/frontNewsDic`,
      method: "get",
      params,
    });
  },
  // 查询所有字典
  getDic(params) {
    return request({
      url: `web/sys-dictionary/front/newsDic`,
      method: "get",
      params,
    });
  },
  // 根据父极code查下级字典
  getDicByParentCode(params) {
    return request({
      url: `web/sys-dictionary/front/listAllChildrenAndParent`,
      method: "get",
      params,
    });
  },
  // 查询已发布的banner列表
  getBannerList() {
    return request({
      url: `web/sys-banner/front/listFrontBanner`,
      method: "get",
    });
  },
  // 查询新闻
  getNewsList(params) {
    return request({
      url: `web/sys-news/front`,
      method: "get",
      params,
    });
  },
  // 查询上下一条新闻
  getLastestNew(params) {
    return request({
      url: `web/sys-news/front/nextAndLastNews`,
      method: "get",
      params,
    });
  },
  // 查询根据id查新闻详情
  getNewDetail(id) {
    return request({
      url: `web/sys-news/front/${id}`,
      method: "get",
    });
  },
  // 查询全部医生
  getAllDoctor(params) {
    return request({
      url: `web/experts-team/front/listExpertsTeamList`,
      method: "get",
      params,
    });
  },
  // 分页查询医生
  getDoctorList(params) {
    return request({
      url: `web/experts-team/front/pageFrontExpertsTeamList`,
      method: "get",
      params,
    });
  },
  // 分页查询医生
  getDoctorDetail(params) {
    return request({
      url: `web/experts-team/front/getExpertsTeamById`,
      method: "get",
      params,
    });
  },
  // 全局配置
  getConfig() {
    return request({
      url: `web/sys-web-config/front/frontListConfig`,
      method: "get",
    });
  },
  // 学科
  getAllSubject() {
    return request({
      url: `web/administrative-office/front/getOfficeList`,
      method: "get",
    });
  },
  getSubjectList(params) {
    return request({
      url: `web/administrative-office/front/pageFrontAdministrativeOffice`,
      method: "get",
      params,
    });
  },
  getSubjectByCodes(params) {
    return request({
      url: `web/administrative-office/front/listOfficeByOfficeCodes`,
      method: "get",
      params,
    });
  },
  // 招聘信息
  getJobList(params) {
    return request({
      url: `web/sys-job/front/listJobByCondition`,
      method: "get",
      params,
    });
  },
  // 所有招聘信息
  getAllJob(params) {
    return request({
      url: `web/sys-job/front/listMJobByCondition`,
      method: "get",
      params,
    });
  },
  // 专病
  getAllClinic(params) {
    return request({
      url: `web/expert-clinic/front/listFrontKeySubject`,
      method: "get",
      params,
    });
  },
  // 留言
  addMessage(data) {
    return request({
      url: `web/message-form/front/saveOrUpdate`,
      method: "post",
      data,
    });
  },
  // 预约
  addAppoint(data) {
    return request({
      url: `web/appointment-list/front/saveOrUpdate`,
      method: "post",
      data,
    });
  },
};
