<!-- -->
<template>
  <div class="frame">
    <Banner />
    <BreadCrumb />
    <div class="newsInfo">
      <div class="w1280 f-cb">
        <div class="conLeft">
          <div class="title">
            <div class="tits">{{ detail.title }}</div>
            <div class="tit">
              <span
                >发布时间：
                {{ detail.publishTime | handleTime(detail.createTime) }} </span
              >&nbsp;&nbsp;<span>本文来源： {{ globalConfig.name }}</span>
            </div>
          </div>
          <div class="con" v-html="detail.contentStr"></div>
          <div class="paging f-cb">
            <div class="left">
              <p>
                <router-link
                  v-if="last.id"
                  :to="last.templateDesc + '_' + last.dictId + '/' + last.id"
                  >上一篇：{{ last.title }}</router-link
                >
                <a v-else href="javascript:;">上一篇：无</a>
              </p>
              <p>
                <router-link
                  v-if="next.id"
                  :to="next.templateDesc + '_' + next.dictId + '/' + next.id"
                  >下一篇：{{ next.title }}</router-link
                >
                <a v-else href="javascript:;">下一篇：无</a>
              </p>
            </div>
            <div class="share f-cb">
              <span>分享到：</span>
              <div class="bshare-custom f-cb">
                <a title="分享到微信" class="bshare-weixin"></a>
                <a title="分享到新浪微博" class="bshare-sinaminiblog"></a>
                <a title="分享到QQ空间" class="bshare-qzone"></a>
                <a title="分享到Facebook" class="bshare-facebook"></a>
              </div>
            </div>
          </div>
        </div>
        <div class="conRight">
          <div class="item2">
            <div class="title">便民服务</div>
            <ul class="f-cb">
              <li v-for="item in serverList" :key="item.name">
                <router-link :to="item.path">
                  <div class="icon">
                    <img :src="item.icon" class="img1" />
                  </div>
                  <p>{{ item.name }}</p>
                </router-link>
              </li>
            </ul>
          </div>

          <div class="item1 animation">
            <div class="title">相关文章</div>
            <ul>
              <li v-for="(item, idx) in articleList" :key="item.id">
                <router-link
                  :to="item.templateDesc + '_' + item.dictId + '/' + item.id"
                  class="f-cb"
                >
                  <span>{{ idx + 1 }}</span>
                  <div class="t">{{ item.title }}</div>
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Banner from "../components/banner.vue";
import BreadCrumb from "../components/breadCrumb.vue";
import { Store } from "@/store/index.js";
import { mapState } from "pinia";
export default {
  data() {
    return {
      detail: {},
      serverList: [
        {
          name: "自助挂号",
          icon: "https://www.sclsnk.com/file/skin/picture/x1.png",
          path: "/formAppoint_9",
        },
        {
          name: "专家门诊",
          icon: "https://www.sclsnk.com/file/skin/picture/x2.png",
          path: "/doctorClinic_116",
        },
        {
          name: "报告查询",
          icon: "https://www.sclsnk.com/file/skin/picture/x3.png",
          path: "/reportSearch_115",
        },
        {
          name: "就医流程",
          icon: "https://www.sclsnk.com/file/skin/picture/x4.png",
          path: "/procedure_114",
        },
      ],
      articleList: [],
      last: {},
      next: {},
    };
  },
  computed: {
    ...mapState(Store, ["globalConfig"]),
  },
  components: { Banner, BreadCrumb },
  created() {
    this.getDetail();
    this.getLastest();
    this.getList();
  },
  methods: {
    getDetail() {
      this.$api.getNewDetail(this.$route.params.child).then((res) => {
        this.detail = res.data;
      });
    },
    getList() {
      const params = {
        pageIndex: 1,
        pageSize: 10,
        dictIds: this.$route.params.id,
      };
      this.$api.getNewsList(params).then((res) => {
        this.articleList = res.data.list;
      });
    },
    getLastest() {
      const params = {
        pageIndex: 1,
        pageSize: 100,
        newsId: this.$route.params.child,
        dictIds: this.$route.params.id,
      };
      this.$api.getLastestNew(params).then((res) => {
        this.last = res.data.last || {};
        this.next = res.data.next || {};
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
