<!-- -->
<template>
  <div class="frame">
    <Banner />
    <BreadCrumb />
    <div class="doctorClinic w1280">
      <div class="top f-cb animation srcospcur">
        <div class="t f-cb">
          <i>门诊时间：</i><span>上午&nbsp;&nbsp;&nbsp;8:00-12:00</span
          ><span>下午&nbsp;&nbsp;&nbsp;13:00-17:00</span>
        </div>
        <div class="t f-cb">
          <i>急诊时间：</i><span>0:00-24:00（24小时接诊）</span>
        </div>
      </div>

      <div class="tableA animation srcospcur">
        <table class="table1">
          <tbody>
            <tr>
              <th class="t1">疾病名称</th>
              <th class="t2">所属科室</th>
              <th class="t3">专病介绍</th>
              <th class="t4">出诊专家</th>
            </tr>
          </tbody>
        </table>

        <table class="table2">
          <tbody>
            <tr v-for="item in list" :key="item.id">
              <td class="t1">{{ item.diseaseName }}</td>
              <td class="t2">{{ item.officeName }}</td>
              <td class="t3">
                <div class="tt">{{ item.diseaseIntroduction }}</div>
              </td>
              <td class="t4">
                <div class="con f-cb">
                  <div class="pic">
                    <img width="100%" :src="item.expertImageUrl" />
                  </div>
                  <div class="conRight">
                    <div class="x1">{{ item.expertName }}</div>
                    <div class="x2">
                      {{ item.position }}<br />出诊：{{ item.callTime }}
                    </div>
                    <a :href="globalConfig.zixun"><span>预约</span></a>
                    <a :href="globalConfig.zixun">
                      <span class="s">咨询</span>
                    </a>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="txt animation">
        * 以上出诊信息仅供参考，具体出诊请以实际门诊安排为准
      </div>
    </div>
  </div>
</template>

<script>
import Banner from "../components/banner.vue";
import BreadCrumb from "../components/breadCrumb.vue";
import { Store } from "@/store/index.js";
import { mapState } from "pinia";
export default {
  data() {
    return {
      list: [],
    };
  },
  components: { Banner, BreadCrumb },
  computed: {
    ...mapState(Store, ["globalConfig"]),
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      this.$api.getAllClinic().then((res) => {
        this.list = res.data;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
