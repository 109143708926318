<!-- -->
<template>
  <div class="frame">
    <BreadCrumb />
    <Nav />
    <div class="newsInfo">
      <div class="x">
        <article>
          <div class="title">
            <div class="tits">{{ detail.title }}</div>
            <div class="tit f-cb">
              <div class="t">
                <span
                  >发布时间：{{
                    detail.publishTime | handleTime(detail.createTime)
                  }}</span
                >&nbsp;&nbsp;<span>本文来源： {{ globalConfig.name }}</span>
              </div>
              <div class="share f-cb">
                <div class="shareNew f-cb">
                  <span>分享到：</span>
                  <div class="bshare-custom f-cb">
                    <div class="bsPromo bsPromo2"></div>
                    <a title="分享到微信" class="bshare-weixin"></a>
                    <a title="分享到新浪微博" class="bshare-sinaminiblog"></a>
                    <a title="分享到QQ空间" class="bshare-qzone"></a>
                    <a title="分享到Facebook" class="bshare-facebook"></a>
                    <!-- <a title="分享到打印" class="bshare-printer"></a> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="con" v-html="detail.contentStr"></div>
        </article>
        <div class="paging">
          <p>
            <router-link
              v-if="last.id"
              :to="last.templateDesc + '_' + last.dictId + '/' + last.id"
              >上一篇：{{ last.title }}</router-link
            >
            <a v-else href="javascript:;">上一篇：无</a>
          </p>
          <p>
            <router-link
              v-if="next.id"
              :to="next.templateDesc + '_' + next.dictId + '/' + next.id"
              >下一篇：{{ next.title }}</router-link
            >
            <a v-else href="javascript:;">下一篇：无</a>
          </p>
        </div>
      </div>
      <div class="box1">
        <div class="titx"><span>相关文章</span></div>
        <ul>
          <li v-for="(item, idx) in articleList" :key="item.id">
            <router-link
              :to="item.templateDesc + '_' + item.dictId + '/' + item.id"
              class="f-cb"
            >
              <span>{{ idx + 1 }}</span>
              <div class="t">{{ item.title }}</div>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
    <div class="mainLine"></div>
  </div>
</template>

<script>
import BreadCrumb from "../../components/breadCrumb.vue";
import Nav from "../../components/mNav.vue";
import { Store } from "@/store/index.js";
import { mapState } from "pinia";
export default {
  data() {
    return {
      navList: [],
      detail: {},
      last: {},
      next: {},
      articleList: [],
      videoList: [],
    };
  },
  computed: {
    ...mapState(Store, ["globalConfig"]),
  },
  components: { BreadCrumb, Nav },
  created() {
    this.getDetail();
    this.getLastest();
    this.getList();
  },
  methods: {
    getDetail() {
      this.$api.getNewDetail(this.$route.params.child).then((res) => {
        this.detail = res.data;
      });
    },
    getList() {
      const params = {
        pageIndex: 1,
        pageSize: 10,
        dictIds: this.$route.params.id,
      };
      this.$api.getNewsList(params).then((res) => {
        this.articleList = res.data.list;
      });
    },
    getLastest() {
      const params = {
        pageIndex: 1,
        pageSize: 100,
        newsId: this.$route.params.child,
        dictIds: this.$route.params.id,
      };
      this.$api.getLastestNew(params).then((res) => {
        this.last = res.data.last || {};
        this.next = res.data.next || {};
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
