import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
Vue.use(ElementUI);

// 按需注入vant
import "./plugins/vantInstall";

import { Store } from "./store";
import { createPinia, PiniaVuePlugin } from "pinia";
// 创建pinia
Vue.use(PiniaVuePlugin);
const pinia = createPinia();
Vue.use(pinia);
// 全局绑定store
const store = Store();
Vue.prototype.$store = store;

import api from "./util/api";
Vue.prototype.$api = api;
Vue.config.productionTip = false;

import "animate.css";
import "wowjs/css/libs/animate.css";
import wow from "wowjs";
Vue.prototype.$wow = wow;

// 时间处理器
Vue.filter("handleTime", (value, fallback) => {
  if (!value && !fallback) return "";
  if (!value) {
    return fallback.split(" ")[0];
  }
  return value.split(" ")[0];
});

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
