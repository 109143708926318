import Layout from "@/layout";

import index from "../views/index.vue";
import about from "../views/about.vue";
import cultureList from "../views/culturelist.vue";
import cultureDetail from "../views/cultureDetail.vue";
import environmentalList from "../views/environmentallist.vue";
import floorGuide from "../views/floorguide.vue";
import subjectList from "../views/subjectlist.vue";
import teamList from "../views/teamList.vue";
import teamDetail from "../views/teamDetail.vue";
import dynamicList from "../views/dynamicList.vue";
import dynamicDetail from "../views/dynamicDetail.vue";
import hosCultureList from "../views/hosCultureList.vue";
import hosCulture from "../views/hosCulture.vue";
import contactUs from "../views/contactUs.vue";
import bus from "../views/bus.vue";
import job from "../views/job.vue";
import procedure from "../views/procedure.vue";
import message from "../views/message.vue";
import search from "../views/search.vue";
import doctorClinic from "../views/doctorClinic.vue";
import appoint from "../views/appoint.vue";
import appointWx from "../views/appointWx.vue";
import appointPhone from "../views/appointPhone.vue";
import appointForm from "../views/appointForm.vue";
import reportSearch from "../views/reportSearch.vue";

export default [
  {
    path: "/",
    component: Layout,
    children: [
      {
        path: "/",
        name: "首页",
        component: index,
      },
      {
        path: "/search",
        name: "搜索",
        component: search,
      },
      {
        path: "/about_:id",
        name: "医院简介",
        component: about,
      },
      {
        path: "/culturelist_:id",
        name: "列表1",
        component: cultureList,
      },
      {
        path: "/culturelist_:id/:child",
        name: "详情1",
        component: cultureDetail,
      },
      {
        path: "/environmentallist_:id",
        name: "列表2",
        component: environmentalList,
      },
      {
        path: "/guide_:id",
        name: "列表3",
        component: floorGuide,
      },
      {
        path: "/subjectlist_:id",
        name: "列表4",
        component: subjectList,
      },
      {
        path: "/teamlist_:id",
        name: "列表5",
        component: teamList,
        meta: {
          keepAlive: true,
        },
      },
      {
        path: "/teamlist_:id/:child",
        name: "详情5",
        component: teamDetail,
      },
      {
        path: "/dynamicList_:id",
        name: "列表6",
        component: dynamicList,
      },
      {
        path: "/dynamicList_:id/:child",
        name: "详情6",
        component: dynamicDetail,
      },
      {
        path: "/hosCultureList_:id",
        name: "列表7",
        component: hosCultureList,
      },
      {
        path: "/hosCultureList_:id/:child",
        name: "详情7",
        component: hosCulture,
      },
      {
        path: "/hosCulture_:id",
        name: "列表8",
        component: hosCulture,
      },
      {
        path: "/contactUs_:id",
        name: "联系方式",
        component: contactUs,
      },
      {
        path: "/bus_:id",
        name: "来院交通",
        component: bus,
      },
      {
        path: "/job_:id",
        name: "人才招聘",
        component: job,
      },
      {
        path: "/procedure_:id",
        name: "就医流程",
        component: procedure,
      },
      {
        path: "/message_:id",
        name: "投诉",
        component: message,
      },
      {
        path: "/doctorClinic_:id",
        name: "专家门诊",
        component: doctorClinic,
      },
      {
        path: "/appoint_:id",
        name: "门诊预约",
        component: appoint,
      },
      {
        path: "/wxAppoint_:id",
        name: "微信预约",
        component: appointWx,
      },
      {
        path: "/phoneAppoint_:id",
        name: "电话预约",
        component: appointPhone,
      },
      {
        path: "/formAppoint_:id",
        name: "表单预约",
        component: appointForm,
      },
      {
        path: "/reportSearch_:id",
        name: "报告查询",
        component: reportSearch,
      },
    ],
  },
];
