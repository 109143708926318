<template>
  <div class="headerBox f-cb">
    <div class="header f-cb">
      <a href="/" class="logo">
        <img width="100%" :src="globalConfig.logoImgUrl" />
      </a>
      <a
        href="javascript:;"
        :class="{ navClick: true, on: sideShow }"
        @click="navClick"
      >
        <span></span>
        <span></span>
        <span></span>
      </a>
      <a :href="'tel:' + globalConfig.hospitalPhoneNo" class="tel"></a>
      <div class="nav" v-show="sideShow">
        <ul>
          <li>
            <router-link to="/" class="tits">首页</router-link>
          </li>
          <li>
            <div class="in" v-for="item in topList" :key="item.id">
              <router-link
                class="tits f-cb"
                :to="item.templateDesc + '_' + item.id"
                >{{ item.name }}<i></i
              ></router-link>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { Store } from "@/store/index.js";
import { mapState } from "pinia";
export default {
  data() {
    return {
      show: false,
      searchData: "",
      path: "",
      businessCode: "", // 业务介绍 code 用于特殊处理
      navShow: false,
      subShow: false,
      sideShow: false,
    };
  },
  computed: {
    ...mapState(Store, ["navList", "globalConfig"]),
    topList() {
      return this.navList.slice(0, 7);
    },
  },
  watch: {
    $route() {
      this.sideShow = false;
    },
  },
  mounted() {
    window.onscroll = function () {
      const nav = document.getElementsByClassName("nav")[0];
      if (window.innerWidth > 1024) {
        if (document.documentElement.scrollTop > 101) {
          nav.classList.add("on");
        } else {
          nav.classList.remove("on");
        }
      }
    };
  },
  methods: {
    navClick() {
      this.sideShow = !this.sideShow;
    },
    changeNav(val, parent) {
      // 提取参数
      const { id, children } = val;
      window.scrollTo(0, 0);
      if (id == this.navList[0].id) {
        // 首页
        this.$router.push("/");
      } else {
        if (parent) {
          // 点击二级字典 直接跳转
          this.$router.push(val.templateName + "_" + parent.id + "/" + val.id);
        } else {
          // 点击一级字典
          const defaultRoute = children[0];
          if (defaultRoute) {
            // 有子级，默认第一个
            this.$router.push(
              defaultRoute.templateName + "_" + val.id + "/" + defaultRoute.id
            );
          } else {
            // 无子级，直接跳转
            this.$router.push(val.templateName + "_" + val.id);
          }
        }
      }

      this.navShow = false;
    },
    startSearch() {
      this.$router.push("/search?keyword=" + this.searchData);
    },
  },
};
</script>