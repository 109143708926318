var render = function render(){var _vm=this,_c=_vm._self._c;return _c('footer',{staticClass:"footerBox"},[_c('div',{staticClass:"bottom"},[_c('div',{staticClass:"aboutTitle"},[_vm._v("便捷就医")]),_c('div',{staticClass:"list"},[_c('ul',{staticClass:"f-cb"},[_vm._l((_vm.quicklyNav),function(item,idx){return _c('li',{key:item.name},[_c('router-link',{attrs:{"to":item.path}},[_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":'https://www.sclsnk.com/file/mskin/picture/index_icon' +
                  (idx + 1) +
                  '.png'}})]),_c('p',[_vm._v(_vm._s(item.name))])])],1)}),_c('li',[_c('a',{attrs:{"href":_vm.globalConfig.zixunm}},[_vm._m(0),_c('p',[_vm._v("在线咨询")])])])],2)]),_c('div',{staticClass:"mainLine"})]),_c('div',{staticClass:"footer"},[_c('div',{staticClass:"logo"},[_c('img',{attrs:{"width":"100%","src":_vm.globalConfig.logoImgUrl}})]),_c('div',{staticClass:"con"},[_c('div',{staticClass:"item f-cb"},[_c('div',{staticClass:"tits"},[_vm._v("联系我们")]),_c('div',{staticClass:"tit"},[_c('div',{staticClass:"t f-cb"},[_c('span',[_vm._v("医院电话")]),_c('em'),_c('i',[_vm._v(_vm._s(_vm.globalConfig.hospitalPhoneNo))])]),_vm._m(1),_vm._m(2),_vm._m(3)])]),_c('div',{staticClass:"item f-cb"},[_c('div',{staticClass:"tits"},[_vm._v("交通路线")]),_c('div',{staticClass:"tit"},[_c('div',{staticClass:"t f-cb"},[_c('span',[_vm._v("医院地址")]),_c('em'),_c('i',[_vm._v(_vm._s(_vm.globalConfig.companyAddress))])]),_c('div',{staticClass:"t f-cb"},[_c('span',[_vm._v("地铁线路")]),_c('em'),_c('i',[_vm._v(_vm._s(_vm.globalConfig.subwayLine))])]),_c('div',{staticClass:"t f-cb"},[_c('span',[_vm._v("公交线路")]),_c('em'),_c('i',[_vm._v(_vm._s(_vm.globalConfig.transportLine))])]),_c('div',{staticClass:"t f-cb"},[_c('span',[_vm._v("公交线路")]),_c('em'),_c('i',[_vm._v(_vm._s(_vm.globalConfig.transportLine2))])])])])]),_c('div',{staticClass:"foot"},[_c('div',{staticClass:"tit"},[_c('div',{staticClass:"t f-cb"},[_c('span',[_vm._v("备案编号")]),_c('em'),_c('i',[_c('a',{staticStyle:{"color":"#fff"},attrs:{"href":"https://beian.miit.gov.cn/","target":"_self"}},[_vm._v(_vm._s(_vm.globalConfig.icpMsg))])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":"https://www.sclsnk.com/file/mskin/picture/bottom_icon6.png"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"t f-cb"},[_c('span',[_vm._v("门诊时间")]),_c('em'),_c('i',[_vm._v("08：00-17：00（节假日不休）")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"t f-cb"},[_c('span',[_vm._v("急诊时间")]),_c('em'),_c('i',[_vm._v("00：00-24：00（节假日不休）")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"t f-cb"},[_c('span',[_vm._v("咨询时间")]),_c('em'),_c('i',[_vm._v("7：00-22：30（节假日不休）")])])
}]

export { render, staticRenderFns }