import Vue from "vue";

import {
  NavBar,
  Icon,
  Popup,
  Switch,
  Swipe,
  SwipeItem,
  Sticky,
  Tabs,
  Tab,
  Form,
  Field,
  Toast,
  DropdownMenu,
  DropdownItem,
  cell,
  Tabbar,
  TabbarItem,
} from "vant";
Vue.use(NavBar);
Vue.use(Icon);
Vue.use(Popup);
Vue.use(Switch);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Sticky);
Vue.use(Tabs);
Vue.use(Tab);
Vue.use(Form);
Vue.use(Field);
Vue.use(Toast);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(cell);
Vue.use(Tabbar);
Vue.use(TabbarItem);
