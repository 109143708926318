<template>
  <div class="frame">
    <Banner />
    <BreadCrumb />
    <div class="contact">
      <div class="itemBox w1280">
        <div class="item animation">
          <div class="tab f-cb">
            <a
              href="javascript:;"
              :class="{ on: activeIdx == idx }"
              v-for="(item, idx) in list"
              :key="item.id"
              @click="changeIdx(idx)"
              >{{ item.title }}</a
            >
          </div>
          <div class="list animation">
            <ul>
              <li
                v-for="(item, idx) in list"
                :key="item.id"
                :style="{ display: activeIdx == idx ? 'block' : 'none' }"
              >
                <div class="layout" v-html="item.contentStr"></div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Banner from "../components/banner.vue";
import BreadCrumb from "../components/breadCrumb.vue";
export default {
  data() {
    return {
      list: [],
      activeIdx: 0,
    };
  },
  components: { Banner, BreadCrumb },
  created() {
    this.getList();
  },
  methods: {
    changeIdx(idx) {
      this.activeIdx = idx;
    },
    getList() {
      const params = {
        pageIndex: 1,
        pageSize: 100,
        dictIds: this.$route.params.id,
      };
      this.$api.getNewsList(params).then((res) => {
        this.list = res.data.list;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
