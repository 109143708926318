<!-- -->
<template>
  <div class="frame">
    <Banner />
    <div class="subject">
      <div class="tabsBox w1280 animation">
        <div
          class="t f-cb t-padding-bottom"
          v-for="item in subjectList"
          :key="item.id"
        >
          <div class="tits">{{ item.officeName }}</div>
          <div class="tit f-cb tits">
            <a
              href="javascript:;"
              :class="{ actives: activeSubject.id == child.id }"
              v-for="child in item.children"
              :key="child.id"
              @click="changeSubject(child)"
              >{{ child.officeName }}</a
            >
          </div>
        </div>
      </div>
      <div class="box1">
        <div class="w1280">
          <div class="home f-cb animation srcospcur">
            <a href="/">首页</a>
            <span></span>
            <i>{{ activeSubject.officeName }}</i>
          </div>
          <div class="content f-cb">
            <div class="pic animation">
              <img width="100%" :src="activeSubject.imageUrl" />
            </div>
            <div class="con animation">
              <div class="tits">{{ activeSubject.officeName }}</div>
              <div class="tit">
                <p>{{ activeSubject.content }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="box2">
        <div class="w1280">
          <div class="indexTitle animation">
            <div class="tits">专家介绍</div>
            <div class="line"></div>
          </div>
          <div class="list animation" id="swiper">
            <ul class="f-cb swiper-wrapper">
              <li
                class="swiper-slide"
                v-for="(item, idx) in proList"
                :key="idx"
              >
                <router-link :to="'teamlist_3/' + item.id">
                  <div class="pic">
                    <img width="100%" :src="item.imageHeaderUrl" />
                  </div>
                  <div class="tits">{{ item.name }}</div>
                  <div class="tit">{{ item.position }}</div>
                </router-link>
              </li>
            </ul>
            <div class="btns">
              <div class="btnsNew f-cb">
                <a
                  href="javascript:;"
                  class="a prev"
                  @click="changeSwiper(0, 'prev')"
                ></a>
                <div class="spanBox">
                  <span
                    :class="{
                      'swiper-pagination-bullet': true,
                      'swiper-pagination-bullet-active': idx == activeSwiper,
                    }"
                    v-for="(item, idx) in proLen"
                    :key="item"
                    @click="changeSwiper(idx)"
                  ></span>
                </div>
                <a
                  href="javascript:;"
                  class="a next"
                  @click="changeSwiper(0, 'next')"
                ></a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="box2 box4">
        <div class="w1280">
          <div class="indexTitle animation">
            <div class="tits">科室动态</div>
            <div class="line"></div>
          </div>
          <div class="newlist">
            <div class="item" v-for="item in newList" :key="item.id">
              <router-link
                :to="item.templateDesc + '_' + item.dictId + '/' + item.id"
              >
                <div class="pic">
                  <img :src="item.newsImageUrl" />
                </div>
                <div class="tits">{{ item.title }}</div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 底部 END -->
    <Side />
  </div>
</template>

<script>
import Banner from "../components/banner.vue";
import Side from "../components/side.vue";
import { Store } from "@/store/index.js";
import { mapState } from "pinia";
export default {
  data() {
    return {
      activeSubject: "",
      proList: [],
      proLen: 0,
      swiper: null,
      activeSwiper: 0,
      newQueryParams: {
        pageIndex: 1,
        pageSize: 100,
      },
      newList: [],
    };
  },
  components: { Banner, Side },
  computed: {
    ...mapState(Store, ["subjectList"]),
  },
  watch: {
    subjectList(val) {
      if (val) {
        this.activeSubject = val[0].children[0];
        if (this.$route.query.type) {
          let arr = this.flatArr(val);
          const item = arr.find((ele) => ele.id == this.$route.query.type);
          this.activeSubject = item;
        }
        this.getDoctor();
        this.getNews();
      }
    },
  },
  created() {
    if (this.subjectList.length != 0) {
      this.activeSubject = this.subjectList[0].children[0];
      if (this.$route.query.type) {
        let arr = this.flatArr(this.subjectList);
        const item = arr.find((ele) => ele.id == this.$route.query.type);
        this.activeSubject = item;
      }
      this.getDoctor();
      this.getNews();
    }
  },
  methods: {
    flatArr(arr) {
      return arr.flatMap((ele) => ele.children);
    },
    changeSubject(child) {
      this.activeSubject = child;
      this.getDoctor();
      this.getNews();
    },
    getDoctor() {
      this.$api
        .getAllDoctor({ administrativeOffice: this.activeSubject.officeName })
        .then((res) => {
          this.proList = res.data;
          this.proLen =
            this.proList.length - 5 < 1 ? 1 : this.proList.length - 5;
          setTimeout(() => {
            this.initSwiper();
          }, 100);
        });
    },
    initSwiper() {
      let vm = this;
      this.swiper = new window.Swiper("#swiper", {
        slidesPerView: 6, // 一页显示数量
        spaceBetween: 20,
        on: {
          slideChangeTransitionEnd() {
            vm.activeSwiper = this.activeIndex;
          },
        },
      });
    },
    changeSwiper(idx, type) {
      if (type == "prev") {
        this.swiper.slidePrev();
        return;
      }
      if (type == "next") {
        this.swiper.slideNext();
        return;
      }
      this.swiper.slideTo(idx);
    },
    getNews() {
      const params = {
        pageIndex: 1,
        pageSize: 12,
        officeCode: this.activeSubject.code,
      };
      this.$api.getNewsList(params).then((res) => {
        this.newList = res.data.list;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.actives {
  background-color: #005183 !important;
  border-color: #005183 !important;
  color: #fff !important;
}
.t-padding-bottom {
  padding: 0px 0px 24px 0px;
}

.box4 {
  background-color: #fff;
}
.box4 .newlist {
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
}
.box4 .item {
  width: 300px;
  margin-right: 20px;
  margin-bottom: 20px;
  cursor: pointer;
}
.box4 .item:hover img {
  transform: scale(1.1);
}
.box4 .item .pic {
  width: 100%;
  height: 160px;
  overflow: hidden;
}
.box4 .item .pic img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: 0.5s;
}
.box4 .item .tits {
  margin-top: 5px;
  color: #333;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.main .subject .box4 .indexTitle .tits {
  color: #0a5989;
}
.main .subject .box4 .indexTitle .line {
  background-color: #0a5989;
}
.main .subject .box4 .indexTitle .line:after {
  background-color: #0a5989;
}

#swiper {
  position: relative;
  overflow: hidden;
}
.swiper-pagination-bullet {
  cursor: pointer;
}
</style>
