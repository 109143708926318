<!-- -->
<template>
  <div class="frame">
    <BreadCrumb />
    <Banner />
    <Nav />
    <div class="WeChat">
      <div class="box1">
        <div class="title">四川蓝生脑科医院”电话预约</div>

        <div class="con f-cb">
          <div class="conNew t">
            1 、拨打门诊电话：<span>{{ globalConfig.hospitalPhoneNo }}</span
            ><br />
            2 、就诊当日凭有效身份证件到一楼大厅(挂号处)缴费取号就诊。
          </div>
        </div>
      </div>
      <div class="box2">
        <div class="boxNew">
          <div class="tits">温馨提示</div>

          <div class="tit">
            1
            、预约挂号为免费服务，请使用患者真实信息进行实名制预约；仅支持护照、军官证、港澳通行证、身份证预约；<br />
            2
            、请预留准确的联系电话，以便因临时出诊信息变动，我们可以及时通知到您；<br />
            3
            、预约成功后因故需取消原预约信息的，请于就诊前一个工作日15点之前于预约订单内取消；<br />
            4 、按预约时间提前30分诊前来就诊；<br />
            5
            、上午看诊后需下午看结果者，请在自助机或者人工窗口领取免费复诊号；隔日复诊请挂号就诊；<br />
            6 、我院为就诊患者免费提供病历本，无需购买；<br />
            7 、当日挂号后可直接就诊。预约挂号需取号后就诊。
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Banner from "@/components/mBanner.vue";
import BreadCrumb from "@/components/breadCrumb.vue";
import Nav from "../../components/mNav.vue";
import { Store } from "@/store/index.js";
import { mapState } from "pinia";
export default {
  data() {
    return {};
  },
  components: { Banner, BreadCrumb, Nav },
  computed: {
    ...mapState(Store, ["globalConfig"]),
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.in {
  margin: 60px 0 80px;
}
.in .way {
  font-size: 18px;
  text-align: center;
  margin-top: 40px;
}
.way p {
  margin-bottom: 10px;
}
.imgs {
  display: flex;
  justify-content: center;
}
.img + .img {
  margin-left: 30px;
}
.img img {
  width: 170px;
  height: 170px;
}
.img p {
  text-align: center;
  margin-top: 5px;
  font-size: 16px;
}
</style>
