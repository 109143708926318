<!-- -->
<template>
  <div class="frame">
    <Banner />
    <BreadCrumb />
    <div class="registration">
      <div class="w1280">
        <div class="title animation srcospcur">
          <div class="t">自助提交预约挂号</div>
          <div class="t1">
            *
            您的信息已加密，请放心填写。提交成功后，客服会第一时间与您取得联系。
          </div>
        </div>
        <div class="tableBox f-cb animation srcospcur">
          <form
            action="{pboot:form fcode=2}"
            onSubmit="return submsgSelf(this);"
            method="post"
          >
            <div class="t f-cb">
              <span
                style="
                  background-image: url('https://www.sclsnk.com/file/skin/picture/table_icon1.png');
                "
              ></span>
              <input
                type="text"
                class="text"
                placeholder="请输入您的姓名"
                v-model="addParams.userName"
              />
            </div>
            <div class="t f-cb">
              <span
                style="
                  background-image: url('https://www.sclsnk.com/file/skin/picture/table_icon2.png');
                "
              ></span>
              <input
                type="number"
                class="text"
                placeholder="请输入您的手机号码"
                v-model="addParams.phoneNo"
              />
            </div>
            <div class="t f-cb">
              <span
                style="
                  background-image: url('https://www.sclsnk.com/file/skin/picture/table_icon3.png');
                "
              ></span>
              <select class="sel" v-model="addParams.gender">
                <option value="">请选择您的性别</option>
                <option value="男">男</option>
                <option value="女">女</option>
              </select>
              <i></i>
            </div>
            <div class="t f-cb">
              <span
                style="
                  background-image: url('https://www.sclsnk.com/file/skin/picture/table_icon4.png');
                "
              ></span>
              <select class="sel" v-model="section" @change="getDoctor">
                <option value="">请选择科室</option>
                <optgroup
                  :label="item.officeName"
                  v-for="item in subjectList"
                  :key="item.id"
                >
                  <option
                    :value="child.officeName + '_' + child.id"
                    v-for="child in item.children"
                    :key="child.id"
                  >
                    {{ child.officeName }}
                  </option>
                </optgroup>
              </select>
              <i></i>
            </div>
            <div class="t f-cb">
              <span
                style="
                  background-image: url('https://www.sclsnk.com/file/skin/picture/table_icon5.png');
                "
              ></span>
              <select class="sel" v-model="addParams.expertsTeamId">
                <option value="">请选择专家</option>
                <option
                  :value="item.id"
                  v-for="item in doctorList"
                  :key="item.id"
                >
                  {{ item.name }}
                </option>
              </select>
              <i></i>
            </div>
            <div class="t f-cb">
              <span
                style="
                  background-image: url('https://www.sclsnk.com/file/skin/picture/table_icon6.png');
                "
              ></span>
              <input
                type="text"
                class="text"
                placeholder="预约日期"
                v-model="addParams.appointmentDate"
              />
              <i></i>
            </div>
            <div class="t1">
              <span
                style="
                  background-image: url('https://www.sclsnk.com/file/skin/picture/table_icon7.png');
                "
              ></span>
              <textarea
                class="txt"
                placeholder="请进行症状描述"
                v-model="addParams.symptomDescription"
              ></textarea>
            </div>
            <div class="f-cb"></div>
            <div class="t2 f-cb">
              <button type="button" class="submit submitBtn" @click="add">
                <a>预约提交</a>
              </button>
              <button type="button" class="submit reset" @click="reset">
                重新填写
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Banner from "../components/banner.vue";
import BreadCrumb from "../components/breadCrumb.vue";
import { Store } from "@/store/index.js";
import { mapState } from "pinia";
export default {
  data() {
    return {
      section: "",
      addParams: {
        userName: "",
        phoneNo: "",
        gender: "",
        officeId: "", // 科室id
        expertsTeamId: "", // 专家
        appointmentDate: "", // 日期
        symptomDescription: "", // 描述
      },
      doctorList: [],
    };
  },
  components: { Banner, BreadCrumb },
  computed: {
    ...mapState(Store, ["subjectList"]),
  },
  methods: {
    getDoctor() {
      if (!this.section) {
        this.addParams.officeId = "";
        this.doctorList = [];
        return;
      }
      const name = this.section.split("_")[0];
      this.addParams.officeId = this.section.split("_")[1];
      this.$api.getAllDoctor({ administrativeOffice: name }).then((res) => {
        this.doctorList = res.data;
      });
    },
    add() {
      this.$api.addAppoint(this.addParams).then((res) => {
        if (res.code == 0) {
          this.$notify.success({ title: "谢谢您的反馈，我们会尽快联系您！" });
          this.reset();
        }
      });
    },
    reset() {
      this.section = "";
      Object.assign(this.$data.addParams, this.$options.data().addParams);
    },
  },
};
</script>

<style lang="scss" scoped>
.in {
  margin: 60px 0 80px;
}
.in .way {
  font-size: 18px;
  text-align: center;
  margin-top: 40px;
}
.way p {
  margin-bottom: 10px;
}
.imgs {
  display: flex;
  justify-content: center;
}
.img + .img {
  margin-left: 30px;
}
.img img {
  width: 170px;
  height: 170px;
}
.img p {
  text-align: center;
  margin-top: 5px;
  font-size: 16px;
}
</style>
