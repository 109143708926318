<!-- -->
<template>
  <div class="frame">
    <Banner />
    <BreadCrumb />
    <div class="research">
      <div class="w1280">
        <div class="navs">
          <router-link
            :to="item.templateDesc + '_' + item.id"
            :class="{
              nav: true,
              on: item.templateDesc + '_' + item.id == $route.path,
            }"
            v-for="item in navList"
            :key="item.name"
            >{{ item.name }}</router-link
          >
        </div>
        <div class="list f-cb">
          <div class="listNew f-cb" id="datalist">
            <div class="item animation" v-for="item in list" :key="item.id">
              <router-link :to="'/dynamiclist_' + item.dictId + '/' + item.id">
                <div class="pic">
                  <img width="100%" :src="item.newsImageUrl" />
                </div>
                <div class="con">
                  <div class="time">
                    {{ item.publishTime | handleTime(item.createTime) }}
                  </div>
                  <div class="tits">{{ item.title }}</div>
                  <div class="tit">{{ item.description }}</div>
                  <div class="more">MORE ></div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Banner from "../components/banner.vue";
import BreadCrumb from "../components/breadCrumb.vue";
export default {
  data() {
    return {
      navList: [],
      list: [],
    };
  },
  components: { Banner, BreadCrumb },
  created() {
    this.getNavList();
    this.getList();
  },
  methods: {
    getNavList() {
      this.$api
        .getDicByParentCode({ code: "0c4997cc6d6244b989f0ad98e0779b5b" })
        .then((res) => {
          this.navList = res.data[0].children;
        });
    },
    getList() {
      const params = {
        pageIndex: 1,
        pageSize: 50,
        dictIds: "112",
        // dictIds: this.$route.params.id,
      };
      this.$api.getNewsList(params).then((res) => {
        this.list = res.data.list;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.list .f-cb {
  display: flex;
  flex-wrap: wrap;
}
.navs {
  display: flex;
  justify-content: center;
}
.navs .nav {
  width: 150px;
  text-align: center;
  color: #333;
  padding: 10px 0;
  border-radius: 20px;
  font-size: 18px;
  background-color: #eee;
}
.navs .nav:hover {
  color: #fff;
  background-color: #16559c;
}
.navs .nav + .nav {
  margin-left: 10px;
}
.navs .on {
  color: #fff;
  font-weight: bold;
  background-color: #16559c;
}
</style>
