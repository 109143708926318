<!-- -->
<template>
  <div class="frame">
    <Banner />
    <BreadCrumb />
    <div class="environment">
      <div class="w1280">
        <div class="bigImg animation" v-if="list.length != 0">
          <img id="img" :src="list[activeIdx].newsImageUrl" />
          <div class="t" id="title">{{ list[activeIdx].title }}</div>
          <a href="javascript:;" class="a prev" @click="preItem"></a>
          <a href="javascript:;" class="a next" @click="nextItem"></a>
        </div>
        <div class="list animation">
          <div id="swiper" class="listNew swiper-container">
            <ul class="f-cb swiper-wrapper">
              <li
                :class="{ 'swiper-slide': true, on: idx == activeIdx }"
                v-for="(item, idx) in list"
                :key="item.id"
                @click="changeItem(idx)"
              >
                <img width="100%" :src="item.newsImageUrl" />
                <div class="bg"></div>
              </li>
            </ul>
          </div>
          <a href="javascript:;" class="a prev" @click="preSwiper"></a>
          <a href="javascript:;" class="a next" @click="nextSwiper"></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Banner from "../components/banner.vue";
import BreadCrumb from "../components/breadCrumb.vue";
export default {
  data() {
    return {
      swiper: null,
      list: [],
      activeIdx: 0, // 激活的图片下标
      activeSwiper: 0, // 激活的swiper下标
    };
  },
  components: { Banner, BreadCrumb },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      const params = {
        pageIndex: 1,
        pageSize: 100,
        dictIds: "106",
      };
      this.$api.getNewsList(params).then((res) => {
        this.list = res.data.list;

        setTimeout(() => {
          this.initSwiper();
        }, 100);
      });
    },
    initSwiper() {
      let vm = this;
      this.swiper = new window.Swiper("#swiper", {
        slidesPerView: 6, // 一页显示数量
        spaceBetween: 23,
        on: {
          slideChangeTransitionEnd() {
            vm.activeSwiper = this.activeIndex;
          },
        },
      });
    },
    preItem() {
      if (this.activeIdx == 0) {
        this.activeIdx = this.list.length - 1;
        return;
      }
      this.activeIdx--;
    },
    nextItem() {
      if (this.activeIdx == this.list.length - 1) {
        this.activeIdx = 0;
        return;
      }
      this.activeIdx++;
    },
    changeItem(idx) {
      this.activeIdx = idx;
    },
    preSwiper() {
      this.swiper.slidePrev();
    },
    nextSwiper() {
      this.swiper.slideNext();
    },
  },
};
</script>

<style lang="scss" scoped>
#swiper {
  position: relative;
  overflow: hidden;
}
</style>
