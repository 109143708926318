<!-- -->
<template>
  <div class="frame">
    <Banner />
    <BreadCrumb />
    <div class="contain">
      <div class="w1280">
        <div class="navs">
          <router-link
            :to="item.templateDesc + '_' + item.id"
            :class="{
              nav: true,
              on: item.templateDesc + '_' + item.id == $route.path,
            }"
            v-for="item in navList"
            :key="item.name"
            >{{ item.name }}</router-link
          >
        </div>
        <div class="in" v-html="detail.contentStr"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Banner from "../components/banner.vue";
import BreadCrumb from "../components/breadCrumb.vue";
export default {
  data() {
    return {
      navList: [],
      detail: {},
    };
  },
  components: { Banner, BreadCrumb },
  created() {
    this.getNavList();
    this.getDetail();
  },
  methods: {
    getNavList() {
      this.$api
        .getDicByParentCode({ code: "0c4997cc6d6244b989f0ad98e0779b5b" })
        .then((res) => {
          this.navList = res.data[0].children;
        });
    },
    getDetail() {
      const params = {
        pageIndex: 1,
        pageSize: 1,
        dictIds: this.$route.params.id,
      };
      this.$api.getNewsList(params).then((res) => {
        this.detail = res.data.list[0] || {};
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.contain {
  padding: 30px 0 50px;
}
.navs {
  display: flex;
  justify-content: center;
}
.navs .nav {
  width: 150px;
  text-align: center;
  color: #333;
  padding: 10px 0;
  border-radius: 20px;
  font-size: 18px;
  background-color: #eee;
}
.navs .nav:hover {
  color: #fff;
  background-color: #16559c;
}
.navs .nav + .nav {
  margin-left: 10px;
}
.navs .on {
  color: #fff;
  font-weight: bold;
  background-color: #16559c;
}
.in {
  margin-top: 26px;
}
</style>
