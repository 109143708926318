<!-- -->
<template>
  <div class="frame">
    <BreadCrumb />
    <Banner />
    <Nav />
    <div class="contain">
      <div class="navs">
        <router-link
          :to="item.templateDesc + '_' + item.id"
          :class="{
            nav: true,
            on: item.templateDesc + '_' + item.id == $route.path,
          }"
          v-for="item in subList"
          :key="item.name"
          >{{ item.name }}</router-link
        >
      </div>
      <div class="in" v-html="detail.contentStr"></div>
    </div>
  </div>
</template>

<script>
import Banner from "@/components/mBanner.vue";
import BreadCrumb from "@/components/breadCrumb.vue";
import Nav from "../../components/mNav.vue";
export default {
  data() {
    return {
      subList: [
        { name: "院徽", path: "/hosCulture_10" },
        { name: "院歌", path: "/hosCulture_10" },
        { name: "蓝生文化", path: "/hosCulture_10" },
        { name: "员工关怀", path: "/hosCultureList_10" },
      ],
      detail: {},
    };
  },
  components: { Banner, BreadCrumb, Nav },
  created() {
    this.getNavList();
    this.getDetail();
  },
  methods: {
    getNavList() {
      this.$api
        .getDicByParentCode({ code: "0c4997cc6d6244b989f0ad98e0779b5b" })
        .then((res) => {
          this.subList = res.data[0].children;
        });
    },
    getDetail() {
      const params = {
        pageIndex: 1,
        pageSize: 1,
        dictIds: this.$route.params.id,
      };
      this.$api.getNewsList(params).then((res) => {
        this.detail = res.data.list[0] || {};
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.contain .navs {
  width: fit-content;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 10px auto 20px;
  border: 1px solid #16559c;
  border-radius: 20px;
  overflow: hidden;
}
.navs .nav {
  width: 70px;
  text-align: center;
  color: #333;
  padding: 5px 0;
}
.navs .nav + .nav {
  border-left: 1px solid #16559c;
}
.navs .on {
  color: #fff;
  font-weight: bold;
  background-color: #16559c;
}
.in {
  padding: 0 15px;
  margin-bottom: 20px;
}
.in ::v-deep {
  img,
  video {
    max-width: 100%;
    height: auto !important;
  }
}
</style>
