<!-- -->
<template>
  <div class="mainFx">
    <div class="aBox">
      <router-link to="/formAppoint_9">
        <div class="icon">
          <img src="https://www.sclsnk.com/file/skin/picture/nav_icon1.png" />
        </div>
        <p>自助挂号</p>
      </router-link>
      <router-link to="/contactUs_8">
        <div class="icon">
          <img src="https://www.sclsnk.com/file/skin/picture/nav_icon8.png" />
        </div>
        <p>在线咨询</p>
      </router-link>
      <a href="javascript:;" onclick="return false">
        <div class="icon">
          <img src="https://www.sclsnk.com/file/skin/picture/icon4.png" />
        </div>
        <p>电话咨询</p>
      </a>
      <router-link to="/subjectlist_3">
        <div class="icon">
          <img src="https://www.sclsnk.com/file/skin/picture/icon5.png" />
        </div>
        <p>科室导航</p>
      </router-link>
    </div>
    <!--<div class="share">-->
    <!--    <a href="javascript:;" style="background-image: url('{pboot:sitepath}/skin/picture/xf_icon1.png'); "></a>-->
    <!--</div>-->
    <a href="javascript:;" class="returnTop" @click="scrollToTop"></a>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
