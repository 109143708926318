<!-- -->
<template>
  <div class="frame">
    <div class="index-banner" id="swiper">
      <ul class="swiper-wrapper f-cb">
        <li class="swiper-slide" v-for="item in bannerList" :key="item.id">
          <img width="100%" :src="item.sourceUrl" />
        </li>
      </ul>
      <div class="swiper-pagination"></div>
      <div class="nav">
        <div class="w1280">
          <ul class="animation">
            <li>
              <router-link to="/formAppoint_9">
                <div class="icon">
                  <img
                    src="https://www.sclsnk.com/file/skin/picture/nav_icon1.png"
                  />
                </div>
                <p>自助挂号</p>
              </router-link>
            </li>
            <li v-for="item in bannerNav" :key="item.id">
              <router-link :to="item.path">
                <div class="icon">
                  <img :src="item.icon" />
                </div>
                <p>{{ item.name }}</p>
              </router-link>
            </li>
            <li>
              <a
                href="https://rgek18.kuaishang.cn/bs/im.htm?cas=73088___307759&fi=90873"
              >
                <div class="icon">
                  <img
                    src="https://www.sclsnk.com/file/skin/picture/nav_icon8.png"
                  />
                </div>
                <p>在线预约</p>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="index">
      <div class="index-box1 bgc">
        <div class="w1280">
          <div class="indexTitle animation srcospcur">
            <div class="tits">{{ about.title }}</div>
            <div class="line"></div>
          </div>
          <div class="tabs f-cb animation srcospcur"></div>
          <div id="SubjectBox SubjectBoxs" class="pic">
            <div class="content f-cb">
              <div class="conLeft f-cb">
                <div class="pic">
                  <img width="100%" :src="about.newsImageUrl" />
                </div>
                <div class="con">
                  <b><div class="tits">四川蓝生脑科医院</div></b>
                  <div class="tit">{{ about.contentStr }}</div>
                  <router-link to="/about_2" class="more">MORE</router-link>
                </div>
              </div>
              <div class="conRight">
                <div class="item">
                  <b><div class="tits">医院科室</div></b>
                  <div class="tit f-cb">
                    <router-link
                      to="/subjectlist_3"
                      v-for="item in subjectList"
                      :key="item.id"
                      >{{ item.officeName }}</router-link
                    >
                    <router-link to="/subjectlist_3">
                      <span>更多</span>
                    </router-link>
                  </div>
                </div>
                <div class="item">
                  <b><div class="tits">相关专家</div></b>
                  <div class="tit f-cb">
                    <router-link
                      :to="'teamlist_3/' + item.id"
                      v-for="item in aboutProList"
                      :key="item.id"
                      >{{ item.name }}</router-link
                    >
                    <router-link to="/teamlist_4">
                      <span>更多</span>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="index-box2 bgc">
        <div class="w1280">
          <div class="indexTitle animation srcospcur">
            <div class="tits">专家团队</div>
            <div class="line"></div>
          </div>
          <div class="list animation">
            <div class="listNew" id="ExpertBox">
              <ul class="f-cb swiper-wrapper">
                <li class="swiper-slide" v-for="item in proList" :key="item.id">
                  <div class="pic">
                    <router-link :to="'teamlist_3/' + item.id">
                      <img width="100%" :src="item.imageHeaderUrl"
                    /></router-link>
                  </div>
                  <router-link :to="'teamlist_3/' + item.id">
                    <div class="tits special">
                      {{ item.name }}<span>{{ item.position }}</span>
                    </div>
                    <div class="tit limit2">
                      专业擅长{{ item.specializeIn }}
                    </div>
                  </router-link>
                  <div class="butBox f-cb">
                    <a :href="globalConfig.zixun" class="but1">
                      <span>预约</span>
                    </a>
                    <router-link :to="'teamlist_3/' + item.id" class="but2">
                      <span>详情</span>
                    </router-link>
                  </div>
                </li>
              </ul>
              <div class="btns">
                <div class="btnsNew f-cb">
                  <a
                    href="javascript:;"
                    class="a prev"
                    tabindex="0"
                    @click="changeProSwiper(0, 'left')"
                  ></a>
                  <div
                    class="
                      spanBox
                      swiper-pagination-clickable swiper-pagination-bullets
                    "
                  >
                    <span
                      :class="{
                        'swiper-pagination-bullet': true,
                        'swiper-pagination-bullet-active': idx == activePro,
                      }"
                      tabindex="0"
                      v-for="(item, idx) in proLen"
                      :key="item"
                      @click="changeProSwiper(idx)"
                    ></span>
                  </div>
                  <a
                    href="javascript:;"
                    class="a next swiper-button-disabled"
                    tabindex="0"
                    @click="changeProSwiper(0, 'right')"
                  ></a>
                </div>
              </div>
              <span
                class="swiper-notification"
                aria-live="assertive"
                aria-atomic="true"
              ></span>
            </div>
          </div>
        </div>
      </div>
      <div
        class="index-box3 bgc"
        style="
          background-image: url('https://www.sclsnk.com/file/skin/picture/index_bg1.jpg');
        "
      >
        <div class="w1280 f-cb">
          <div class="item animation">
            <div class="indexTitle">
              <div class="tits">新闻中心</div>
              <div class="line"></div>
              <router-link to="/dynamiclist_88" class="more">more</router-link>
            </div>
            <div class="top">
              <router-link
                :to="
                  newList[0].templateDesc +
                  '_' +
                  newList[0].dictId +
                  '/' +
                  newList[0].id
                "
                v-if="newList[0]"
              >
                <div class="pic">
                  <img width="100%" :src="newList[0].newsImageUrl" />
                </div>
                <div class="tits">{{ newList[0].title }}</div>
              </router-link>
            </div>
            <div class="list">
              <router-link
                :to="
                  newList[1].templateDesc +
                  '_' +
                  newList[1].dictId +
                  '/' +
                  newList[1].id
                "
                v-if="newList[1]"
                >{{ newList[1].title }}</router-link
              >
              <router-link
                :to="
                  newList[2].templateDesc +
                  '_' +
                  newList[2].dictId +
                  '/' +
                  newList[2].id
                "
                v-if="newList[2]"
                >{{ newList[2].title }}</router-link
              >
            </div>
          </div>
          <div class="item animation">
            <div class="indexTitle">
              <div class="tits">通知公告</div>
              <div class="line"></div>
              <router-link to="/dynamiclist_90" class="more">more</router-link>
            </div>
            <div class="top">
              <router-link
                :to="
                  noticeList[0].templateDesc +
                  '_' +
                  noticeList[0].dictId +
                  '/' +
                  noticeList[0].id
                "
                v-if="noticeList[0]"
              >
                <div class="pic">
                  <img width="100%" :src="noticeList[0].newsImageUrl" />
                </div>
                <div class="tits">{{ noticeList[0].title }}</div>
              </router-link>
            </div>
            <div class="list">
              <router-link
                :to="
                  noticeList[1].templateDesc +
                  '_' +
                  noticeList[1].dictId +
                  '/' +
                  noticeList[1].id
                "
                v-if="noticeList[1]"
                >{{ noticeList[1].title }}</router-link
              >
              <router-link
                :to="
                  noticeList[2].templateDesc +
                  '_' +
                  noticeList[2].dictId +
                  '/' +
                  noticeList[2].id
                "
                v-if="noticeList[2]"
                >{{ noticeList[2].title }}</router-link
              >
            </div>
          </div>
          <div class="item animation">
            <div class="indexTitle">
              <div class="tits">健康科普</div>
              <div class="line"></div>
              <router-link to="/dynamiclist_5" class="more">more</router-link>
            </div>
            <div class="top">
              <router-link
                :to="
                  healthList[0].templateDesc +
                  '_' +
                  healthList[0].dictId +
                  '/' +
                  healthList[0].id
                "
                v-if="healthList[0]"
              >
                <div class="pic">
                  <img width="100%" :src="healthList[0].newsImageUrl" />
                </div>
                <div class="tits">{{ healthList[0].title }}</div>
              </router-link>
            </div>
            <div class="list">
              <router-link
                :to="
                  healthList[1].templateDesc +
                  '_' +
                  healthList[1].dictId +
                  '/' +
                  healthList[1].id
                "
                v-if="healthList[1]"
                >{{ healthList[1].title }}</router-link
              >
              <router-link
                :to="
                  healthList[2].templateDesc +
                  '_' +
                  healthList[2].dictId +
                  '/' +
                  healthList[2].id
                "
                v-if="healthList[2]"
                >{{ healthList[2].title }}</router-link
              >
            </div>
          </div>
        </div>
      </div>
      <div class="index-box5 bgc">
        <div class="w1280">
          <div class="indexTitle tab animation">
            <div class="tit f-cb">
              <a href="javascript:;" class="on">党建动态</a>
            </div>
            <div class="line"></div>
            <router-link to="/culturelist_7" class="more">more</router-link>
          </div>
          <div
            class="content f-cb"
            id="PartybuildingBox"
            style="display: block"
          >
            <div class="item animation">
              <div class="top">
                <router-link
                  :to="
                    partyList[0].templateDesc +
                    '_' +
                    partyList[0].dictId +
                    '/' +
                    partyList[0].id
                  "
                  v-if="partyList[0]"
                >
                  <div class="pic">
                    <img width="100%" :src="partyList[0].newsImageUrl" />
                  </div>
                  <div class="tits">{{ partyList[0].title }}</div>
                </router-link>
              </div>
              <div class="tit">
                <router-link
                  :to="
                    partyList[1].templateDesc +
                    '_' +
                    partyList[1].dictId +
                    '/' +
                    partyList[1].id
                  "
                  v-if="partyList[1]"
                  >{{ partyList[1].title }}</router-link
                >
                <router-link
                  :to="
                    partyList[2].templateDesc +
                    '_' +
                    partyList[2].dictId +
                    '/' +
                    partyList[2].id
                  "
                  v-if="partyList[2]"
                  >{{ partyList[2].title }}</router-link
                >
              </div>
            </div>
            <div class="item animation">
              <div class="top">
                <router-link
                  :to="
                    activityList[0].templateDesc +
                    '_' +
                    activityList[0].dictId +
                    '/' +
                    activityList[0].id
                  "
                  v-if="activityList[0]"
                >
                  <div class="pic">
                    <img width="100%" :src="activityList[0].newsImageUrl" />
                  </div>
                  <div class="tits">{{ activityList[0].title }}</div>
                </router-link>
              </div>
              <div class="tit">
                <router-link
                  :to="
                    activityList[1].templateDesc +
                    '_' +
                    activityList[1].dictId +
                    '/' +
                    activityList[1].id
                  "
                  v-if="activityList[1]"
                  >{{ activityList[1].title }}</router-link
                >
                <router-link
                  :to="
                    activityList[2].templateDesc +
                    '_' +
                    activityList[2].dictId +
                    '/' +
                    activityList[2].id
                  "
                  v-if="activityList[2]"
                  >{{ activityList[2].title }}</router-link
                >
              </div>
            </div>
            <div class="item animation">
              <div class="top">
                <router-link
                  :to="
                    '/dynamiclist_' +
                    memberList[0].dictId +
                    '/' +
                    memberList[0].id
                  "
                  v-if="memberList[0]"
                >
                  <div class="pic">
                    <img width="100%" :src="memberList[0].newsImageUrl" />
                  </div>
                  <div class="tits">{{ memberList[0].title }}</div>
                </router-link>
              </div>
              <div class="tit">
                <router-link
                  :to="
                    '/dynamiclist_' +
                    memberList[1].dictId +
                    '/' +
                    memberList[1].id
                  "
                  v-if="memberList[1]"
                  >{{ memberList[1].title }}</router-link
                >
                <router-link
                  :to="
                    '/dynamiclist_' +
                    memberList[2].dictId +
                    '/' +
                    memberList[2].id
                  "
                  v-if="memberList[2]"
                  >{{ memberList[2].title }}</router-link
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="index-box6 bgc">
        <div class="w1280">
          <div class="indexTitle animation">
            <div class="tit f-cb">
              <a href="javascript:;" class="on">医院资质</a>
            </div>
            <div class="line"></div>
            <router-link to="/about_12" class="more">more</router-link>
          </div>
          <div class="list" id="PicBox">
            <ul class="f-cb">
              <li
                class="animation"
                v-for="item in certificationList"
                :key="item.id"
              >
                <img width="100%" :src="item.newsImageUrl" />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Store } from "@/store/index.js";
import { mapState } from "pinia";
export default {
  data() {
    return {
      about: {},
      bannerList: [],
      bannerNav: [
        {
          name: "专家门诊",
          icon: "https://www.sclsnk.com/file/skin/picture/nav_icon1.png",
          path: "/doctorClinic_116",
        },
        {
          name: "报告查询",
          icon: "https://www.sclsnk.com/file/skin/picture/nav_icon2.png",
          path: "/reportSearch_115",
        },
        {
          name: "就医流程",
          icon: "https://www.sclsnk.com/file/skin/picture/nav_icon3.png",
          path: "/procedure_114",
        },
      ],
      aboutProList: [], // 数组长度为5的医生
      proList: [], // 医生
      proLen: 0,
      proSwiper: null,
      activePro: 0,
      newList: [], // 新闻中心
      noticeList: [], // 通知公告
      healthList: [], // 健康科普
      partyList: [], // 党建动态
      activityList: [], // 公益活动
      memberList: [], // 员工关怀
      certificationList: [],
    };
  },
  computed: {
    ...mapState(Store, ["subjectList", "globalConfig"]),
  },
  mounted() {
    this.initBanner();
    this.getIntro();
    this.initPro();
    this.getNews();
  },
  methods: {
    htmlToText(html) {
      // 创建一个新的DOMParser实例
      var parser = new DOMParser();
      // 使用DOMParser的parseFromString方法将HTML字符串转换为Document对象
      var doc = parser.parseFromString(html, "text/html");
      // 定义一个函数来递归提取所有文本节点的内容
      function extractText(node) {
        var text = "";
        if (node.nodeType === Node.TEXT_NODE) {
          text += node.textContent;
        } else if (node.nodeType === Node.ELEMENT_NODE) {
          for (var i = 0; i < node.childNodes.length; i++) {
            text += extractText(node.childNodes[i]);
          }
        }
        return text;
      }
      // 从Document对象的body开始提取文本
      return extractText(doc.body);
    },
    // 获取医院概况
    getIntro() {
      this.$api.getNewDetail("2").then((res) => {
        this.about = res.data;
        this.about.contentStr = this.htmlToText(res.data.contentStr);
      });
    },
    initBanner() {
      this.$api.getBannerList().then((res) => {
        this.bannerList = res.data;

        setTimeout(() => {
          new window.Swiper("#swiper", {
            loop: true,
            autoplay: true,
            pagination: {
              el: ".swiper-pagination",
              clickable: true,
              bulletClass: "banner-bullet",
              bulletActiveClass: "banner-bullet-active",
            },
          });
        }, 100);
      });
    },
    initPro() {
      let vm = this;
      const params = {
        pageIndex: 1,
        pageSize: 20,
        isRecommend: 1,
      };
      this.$api.getDoctorList(params).then((res) => {
        this.proList = res.data.list;
        this.aboutProList = this.proList.slice(0, 5);
        this.proLen = this.proList.length - 5 < 1 ? 1 : this.proList.length - 5;
        setTimeout(() => {
          this.proSwiper = new window.Swiper("#ExpertBox", {
            slidesPerView: 6, // 一页显示数量
            spaceBetween: 25,
            on: {
              slideChangeTransitionEnd() {
                vm.activePro = this.activeIndex;
              },
            },
          });
        }, 100);
      });
    },
    // 专家swiper下标事件
    changeProSwiper(idx, type) {
      if (type == "left") {
        this.proSwiper.slidePrev();
        return;
      }
      if (type == "right") {
        this.proSwiper.slideNext();
        return;
      }
      this.proSwiper.slideTo(idx);
    },
    // 新闻中心、通知公告、健康科普、党建动态、公益活动、员工关怀、医院资质
    getNews() {
      const codes = {
        newList: "d6322e9f491845638abd07b6bab593a8",
        noticeList: "f1d1e75519d743af9890d9eb9aa20ab6",
        healthList: "84f1b1cdc9594e23b97b5ff2c406de15",
        partyList: "c8d87756da784bca9fd0fa2a7850fd68",
        activityList: "4fdeb206b7e94c5cae5e568d1844f2a5",
        memberList: "3fcb5f0ebcc944349506a083f301079a",
        certificationList: "10aa8e5f6cdd42fd95e5cc18cc1f3191",
      };
      for (const key in codes) {
        const ele = codes[key];
        const params = {
          pageIndex: 1,
          pageSize: 3,
          code: ele,
        };
        this.$api.getNewsList(params).then((res) => {
          this[key] = res.data.list;
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.special {
  height: 38px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.frame {
  width: 100%;

  :deep(.banner-bullet) {
    width: 10px;
    height: 10px;
    background-color: #0568a5;
    border-radius: 10px;
    opacity: 1;
    margin: 0 5px;
    display: inline-block;
    cursor: pointer;
  }
  :deep(.banner-bullet-active) {
    width: 20px;
  }
  .swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal,
  .swiper-pagination-custom,
  .swiper-pagination-fraction {
    bottom: 140px;
  }

  .index-box1 {
    background-image: url("https://www.sclsnk.com/file/skin/picture/index_bg1.jpg");
  }
  .index-box2 {
    background-image: url("https://www.sclsnk.com/file/skin/picture/index_bg2.jpg");
  }
  .index-box5 {
    background-image: url("https://www.sclsnk.com/file/skin/picture/index_bg1.jpg");
  }
  .limit2 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  #ExpertBox {
    position: relative;
    overflow: hidden;
  }
}
</style>
