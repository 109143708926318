<!-- -->
<template>
  <div class="frame">
    <BreadCrumb />
    <Banner />
    <Nav />
    <div class="registration">
      <div class="title">
        <div class="tits">自助提交预约挂号</div>
        <div class="tit">
          *您的信息已加密，请放心填写。提交成功后，客服会第一时间与您取得联系
        </div>
      </div>
      <div class="tableBox">
        <form onSubmit="return false;">
          <div
            class="t f-cb"
            style="
              background-image: url('https://www.sclsnk.com/file/mskin/picture/icon1.png');
            "
          >
            <input
              type="text"
              class="text"
              placeholder="请输入您的姓名"
              v-model="addParams.userName"
            />
          </div>
          <div
            class="t f-cb"
            style="
              background-image: url('https://www.sclsnk.com/file/mskin/picture/icon2.png');
            "
          >
            <input
              type="text"
              class="text"
              placeholder="请输入您的电话号"
              v-model="addParams.phoneNo"
            />
          </div>
          <div
            class="t f-cb"
            style="
              background-image: url('https://www.sclsnk.com/file/mskin/picture/icon3.png');
            "
          >
            <select class="sel" v-model="addParams.gender">
              <option value="">请选择您的性别</option>
              <option value="男">男</option>
              <option value="女">女</option>
            </select>
          </div>
          <div
            class="t f-cb"
            style="
              background-image: url('https://www.sclsnk.com/file/mskin/picture/icon4.png');
            "
          >
            <select class="sel" v-model="section" @change="getDoctor">
              <option value="">请选择科室</option>
              <optgroup
                :label="item.officeName"
                v-for="item in subjectList"
                :key="item.id"
              >
                <option
                  :value="child.officeName + '_' + child.id"
                  v-for="child in item.children"
                  :key="child.id"
                >
                  {{ child.officeName }}
                </option>
              </optgroup>
            </select>
          </div>
          <div
            class="t f-cb"
            style="
              background-image: url('https://www.sclsnk.com/file/mskin/picture/icon5.png');
            "
          >
            <select class="sel" v-model="addParams.expertsTeamId">
              <option value="">请选择专家</option>
              <option
                :value="item.id"
                v-for="item in doctorList"
                :key="item.id"
              >
                {{ item.name }}
              </option>
            </select>
          </div>
          <div
            class="t f-cb"
            style="
              background-image: url('https://www.sclsnk.com/file/mskin/picture/icon6.png');
            "
          >
            <input
              type="text"
              class="text"
              placeholder="预约日期"
              v-model="addParams.appointmentDate"
            />
          </div>
          <div
            class="t f-cb"
            style="
              background-image: url('https://www.sclsnk.com/file/mskin/picture/icon7.png');
            "
          >
            <textarea
              class="txt"
              placeholder="请进行症状描述"
              v-model="addParams.symptomDescription"
            ></textarea>
          </div>
          <button type="button" class="submit submitBtn" @click="add">
            <a>预约提交</a>
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Banner from "@/components/mBanner.vue";
import BreadCrumb from "@/components/breadCrumb.vue";
import Nav from "../../components/mNav.vue";
import { Store } from "@/store/index.js";
import { mapState } from "pinia";
export default {
  data() {
    return {
      section: "",
      addParams: {
        userName: "",
        phoneNo: "",
        gender: "",
        officeId: "", // 科室id
        expertsTeamId: "", // 专家
        appointmentDate: "", // 日期
        symptomDescription: "", // 描述
      },
      doctorList: [],
    };
  },
  components: { Banner, BreadCrumb, Nav },
  computed: {
    ...mapState(Store, ["subjectList"]),
  },
  methods: {
    getDoctor() {
      if (!this.section) {
        this.addParams.officeId = "";
        this.doctorList = [];
        return;
      }
      const name = this.section.split("_")[0];
      this.addParams.officeId = this.section.split("_")[1];
      this.$api.getAllDoctor({ administrativeOffice: name }).then((res) => {
        this.doctorList = res.data;
      });
    },
    add() {
      this.$api.addAppoint(this.addParams).then((res) => {
        if (res.code == 0) {
          this.$notify.success({ title: "谢谢您的反馈，我们会尽快联系您！" });
          this.reset();
        }
      });
    },
    reset() {
      this.section = "";
      Object.assign(this.$data.addParams, this.$options.data().addParams);
    },
  },
};
</script>

<style lang="scss" scoped>
.in {
  margin: 60px 0 80px;
}
.in .way {
  font-size: 18px;
  text-align: center;
  margin-top: 40px;
}
.way p {
  margin-bottom: 10px;
}
.imgs {
  display: flex;
  justify-content: center;
}
.img + .img {
  margin-left: 30px;
}
.img img {
  width: 170px;
  height: 170px;
}
.img p {
  text-align: center;
  margin-top: 5px;
  font-size: 16px;
}
</style>
