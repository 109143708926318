<!-- -->
<template>
  <div class="frame">
    <Banner />
    <BreadCrumb />
    <div class="process w1280">
      <div>
        <div class="title animation srcospcur">门诊就医流程</div>
        <div class="pic animation srcospcur">
          <img
            src="https://www.sclsnk.com/static/upload/image/20220822/1661126169973560.jpg"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Banner from "../components/banner.vue";
import BreadCrumb from "../components/breadCrumb.vue";
export default {
  data() {
    return {};
  },
  components: { Banner, BreadCrumb },
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>
