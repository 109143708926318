<!-- -->
<template>
  <div class="frame">
    <Banner />
    <BreadCrumb />
    <div class="report">
      <div>
        <div class="box1">
          <div class="title animation srcospcur">
            “四川蓝生脑科医院”报告查询
          </div>
          <div class="content f-cb">
            <div class="con animation srcospcur">
              <p>
                为满足广大患者对于检查报告查询需求，我院现设检查报告查询专属客服，为患者提供报告线上查询，远程解读服务，有效节约患者时间和精力。
              </p>
              &nbsp;
              <p>
                该客服专员除了提供以上报告查询功能以外，还可提供的服务有：专家排班信息查询、专家预约、核磁放射等项目预约、体检预约、病房床位预约、手术预约、VIP陪诊、公益惠民、健康科普、意见反馈等等……
              </p>
              &nbsp;

              <p>
                备注：<br />
                1、纸质报告自助打印仅能一次，如代取后便无法再次获取，需要原件报告请到院自取；<br />
                2、报告在每日15点后统一拍照发送。
              </p>

              <div class="ewm">
                <img
                  :src="globalConfig.hospitalServiceNumber"
                  style="width: 100%"
                />
              </div>
            </div>

            <div class="conRight animation srcospcur">
              <div class="t">微信扫描添加客服号</div>

              <div class="x">&nbsp;</div>

              <div class="t t1">
                发送给小佑查询报告的类型：<br />
                DR、CT、MRI （需提供姓名与电话）<br />
                其他各类检验报告（需提供领取报告单的二维码）<br />
                体检报告（需要提供姓名与电话）
              </div>

              <div class="x">&nbsp;</div>

              <div class="t">报告出来后通过拍照的形式发送给您</div>
            </div>
          </div>
        </div>

        <div class="box2">
          <div class="boxNew animation">
            <div class="tits">附：</div>

            <div class="tit">
              <p>1）放射科检查报告领取时间：</p>

              <p style="text-indent: 2em">D R：检查后1小时（含摄片、钼靶）</p>

              <p style="text-indent: 2em">
                造影：输卵管造影后1小时，上消化道造影检查后当日15：00后，全消化道造影后次日9:00后，IVP检查及窦道造影后当日15：00后。
              </p>

              <p style="text-indent: 2em">
                C T ：平扫 检查后2小时；增强 一个工作日后
              </p>

              <p style="text-indent: 2em">CTA：两个工作日后</p>

              <p style="text-indent: 2em">MRI：一个工作日后</p>

              <p>2）急诊检查按急诊报告规定时间内出报告。</p>

              <p>
                3）周末节假日病房DR、CT平扫检查报告请于工作日时领取，如遇急需处理的报告相应提前领取。
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Banner from "../components/banner.vue";
import BreadCrumb from "../components/breadCrumb.vue";
import { Store } from "@/store/index.js";
import { mapState } from "pinia";
export default {
  data() {
    return {};
  },
  components: { Banner, BreadCrumb },
  computed: {
    ...mapState(Store, ["globalConfig"]),
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.in {
  margin: 60px 0 80px;
}
.in .way {
  font-size: 18px;
  text-align: center;
  margin-top: 40px;
}
.way p {
  margin-bottom: 10px;
}
.imgs {
  display: flex;
  justify-content: center;
}
.img + .img {
  margin-left: 30px;
}
.img img {
  width: 170px;
  height: 170px;
}
.img p {
  text-align: center;
  margin-top: 5px;
  font-size: 16px;
}
</style>
