import Vue from "vue";
import VueRouter from "vue-router";

// 区分pc端和移动端路由，根据设备类型渲染路由，实现两套代码同一路由
import pcRoutes from "./pcRoutes.js";
import mRoutes from "./mRoutes.js";
import { _isMobile } from "../util/tool.js";
const renderRoutes = _isMobile() ? mRoutes : pcRoutes;

Vue.use(VueRouter);

const routes = renderRoutes;

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  next();
  if (!to.meta.keepAlive) {
    window.scrollTo({ top: 0 });
  }
});

export default router;
