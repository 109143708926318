<!-- -->
<template>
  <div class="frame">
    <Banner />
    <BreadCrumb />
    <div class="mailbox">
      <div class="w1280">
        <div class="tableBox animation f-cb srcospcur">
          <form onSubmit="return submsg(this);" method="post">
            <div class="t f-cb">
              <span class="one"></span>
              <input
                type="text"
                class="text"
                placeholder="请输入您的姓名"
                v-model="form.userName"
              />
            </div>
            <div class="t f-cb">
              <span class="two"></span>
              <input
                type="number"
                class="text"
                placeholder="请输入您的手机号码"
                v-model="form.phoneNo"
              />
            </div>
            <div class="t f-cb">
              <span class="three"></span>
              <input
                type="text"
                class="text"
                placeholder="请输入邮箱"
                v-model="form.email"
              />
            </div>
            <div class="t f-cb">
              <span class="four"></span>
              <input
                type="text"
                class="text"
                placeholder="请输入联系地址"
                v-model="form.address"
              />
            </div>
            <div class="t1">
              <span class="five"></span>
              <textarea
                class="txt"
                placeholder="请进行问题描述"
                v-model="form.content"
              ></textarea>
            </div>
            <div class="f-cb"></div>
            <div class="t2 f-cb">
              <button class="submit" type="button" @click="submitMsg">
                提 交
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Banner from "../components/banner.vue";
import BreadCrumb from "../components/breadCrumb.vue";
export default {
  data() {
    return {
      form: {
        userName: "",
        phoneNo: "",
        email: "",
        address: "",
        content: "",
      },
    };
  },
  components: { Banner, BreadCrumb },
  methods: {
    submitMsg() {
      this.$api.addMessage(this.form).then((res) => {
        if (res.code == 0) {
          this.$notify.success({ title: "谢谢您的反馈，我们会尽快联系您！" });
          Object.assign(this.$data.form, this.$options.data().form);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.one {
  background-image: url("https://www.sclsnk.com/file/skin/picture/table_icon1.png");
}
.two {
  background-image: url("https://www.sclsnk.com/file/skin/picture/table_icon2.png");
}
.three {
  background-image: url("https://www.sclsnk.com/file/skin/picture/table_icon8.png");
}
.four {
  background-image: url("https://www.sclsnk.com/file/skin/picture/table_icon9.png");
}
.five {
  background-image: url("https://www.sclsnk.com/file/skin/picture/table_icon7.png");
}
</style>
