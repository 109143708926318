import { defineStore } from "pinia";
import api from "../util/api";

export const Store = defineStore("main", {
  state: () => {
    return {
      dictionaryList: [], // 所有字典
      navList: [], // 导航条
      globalConfig: {}, // 全局配置
      isMobile: false, // 是否是手机端
      subjectList: [], // 学科列表
    };
  },
  actions: {
    setIsMobile(bol) {
      this.isMobile = bol;
    },
    // 存储所有字典
    async setAllDic() {
      let res = await api.getDic();
      this.dictionaryList = res.data;
    },
    // 存储导航条
    async setNavList() {
      let res = await api.getNavDic();
      this.navList = res.data;
    },
    // 存储全局配置
    async setConfig() {
      let res = await api.getConfig();
      this.globalConfig = res.data;
    },
    // 存储学科
    async setSubject() {
      let res = await api.getAllSubject();
      this.subjectList = res.data;
    },
  },
});
