<template>
  <div class="frame">
    <BreadCrumb />
    <Banner />
    <Nav />
    <div class="contact">
      <div class="itemBox w1280">
        <div class="item animation">
          <div class="tab f-cb">
            <a
              :class="{ on: idx == activeIdx }"
              href="javascript:;"
              v-for="(item, idx) in list"
              :key="item.id"
              @click="changeIdx(idx)"
              >{{ item.title }}</a
            >
          </div>
          <div class="list animation">
            <ul>
              <li
                v-for="(item, idx) in list"
                :key="item.id"
                v-show="idx == activeIdx"
              >
                <div class="layout" v-html="item.contentStr"></div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Banner from "@/components/mBanner.vue";
import BreadCrumb from "@/components/breadCrumb.vue";
import Nav from "../../components/mNav.vue";
export default {
  data() {
    return {
      list: [],
      activeIdx: 0,
    };
  },
  components: { Banner, BreadCrumb, Nav },
  created() {
    this.getList();
  },
  methods: {
    changeIdx(idx) {
      this.activeIdx = idx;
    },
    getList() {
      const params = {
        pageIndex: 1,
        pageSize: 100,
        dictIds: this.$route.params.id,
      };
      this.$api.getNewsList(params).then((res) => {
        this.list = res.data.list;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.mainM .contact .itemBox {
  margin: 40px auto 0;
}

.mainM .contact .itemBox .item .tab {
  width: 100%;
}

.mainM .contact .itemBox .item .tab a {
  height: 40px;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 40px;
  border: 1px solid #ccc;
  padding: 0 14px;
  float: left;
  display: block;
  color: #666;
  margin-right: 10px;
  margin-bottom: 10px;
}

.mainM .contact .itemBox .item .tab a:last-child {
  margin-right: 0;
}

.mainM .contact .itemBox .item .tab a.on {
  background-color: #16559c;
  color: #fff;
  border-color: #16559c;
}
</style>
