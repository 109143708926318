<!-- -->
<template>
  <div class="frame">
    <BreadCrumb />
    <Banner />
    <Nav />
    <div class="about">
      <div class="box1">
        <div class="tits">{{ globalConfig.name }}</div>
        <div
          :class="{ tit: true, on: isExpand }"
          v-html="aboutNew.contentStr"
        ></div>
        <a href="javascript:;" class="moreClick" @click="expandContent"
          ><span>{{ isExpand ? "收起" : "查看更多" }}</span></a
        >
        <div>
          <img width="100%" :src="aboutNew.newsImageUrl" />
          <div class="icon"></div>
        </div>
      </div>
      <div class="mainLine"></div>
      <div class="box2">
        <div class="aboutTitle">诊疗范围</div>
        <div class="list">
          <ul class="f-cb">
            <li v-for="(item, idx) in subjectList" :key="item.id">
              <router-link :to="'/subjectlist_3?type=' + item.id">
                <div class="icon">
                  <img
                    width="100%"
                    :src="
                      'https://www.sclsnk.com/file/mskin/picture/about_img' +
                      (idx + 1) +
                      '.png'
                    "
                  />
                </div>
                <p>{{ item.officeName }}</p>
              </router-link>
            </li>
          </ul>
          <!--<a href="/wap/subject/" class="moreClick"><span>查看更多</span></a>-->
        </div>
      </div>
      <div class="mainLine"></div>
      <div class="box4">
        <div class="aboutTitle">医院资质</div>
        <div class="list">
          <div class="item" v-for="item in certification" :key="item.id">
            <div class="pic"><img width="100%" :src="item.newsImageUrl" /></div>
            <div class="tits">{{ item.title }}</div>
          </div>
        </div>
      </div>
      <div class="mainLine"></div>
    </div>
  </div>
</template>

<script>
import Banner from "../../components/mBanner.vue";
import BreadCrumb from "../../components/breadCrumb.vue";
import Nav from "../../components/mNav.vue";
import { Store } from "@/store/index.js";
import { mapState } from "pinia";
export default {
  data() {
    return {
      navList: [
        { id: 1, name: "医院简介" },
        { id: 2, name: "医院设备" },
        { id: 3, name: "环境设施" },
        { id: 4, name: "楼层导视" },
      ],
      isExpand: false,
      aboutNew: {},
      subjectList: [],
      activeSubject: 0,
      certQueryParams: {
        pageIndex: 1,
        pageSize: 12,
        code: "10aa8e5f6cdd42fd95e5cc18cc1f3191",
      },
      certification: [],
      totalCert: 20,
    };
  },
  components: { Banner, BreadCrumb, Nav },
  computed: {
    ...mapState(Store, ["globalConfig"]),
  },
  created() {
    this.getIntro();
    this.getSubject();
    this.getCert();
  },
  methods: {
    getIntro() {
      this.$api.getNewDetail("1").then((res) => {
        this.aboutNew = res.data;
      });
    },
    getSubject() {
      // 十大学科对应的code
      const codes =
        "64e70e4540ac4664a7e3b8806ccafbaa,63845d908c6a47dea8c4bf5cdeab0e51,3a72894919494fccbb363bf12860c14a,8a33c23e5aab434f8890e6162d106563,057597bcb8354312b4556bd68fe1d9a9";
      this.$api.getSubjectByCodes({ codes }).then((res) => {
        this.subjectList = res.data;
      });
    },
    expandContent() {
      this.isExpand = !this.isExpand;
    },
    getCert() {
      this.$api.getNewsList(this.certQueryParams).then((res) => {
        this.certification = res.data.list;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.box4 {
  padding-bottom: 0.3rem;
}
.box4 .list {
  width: 89.3%;
  margin: 0.2rem auto 0;
  display: flex;
  flex-wrap: wrap;
}
.box4 .list .item {
  width: 48%;
  margin-bottom: 10px;
}
.box4 .list .item:nth-child(2n) {
  margin-left: 4%;
}
.box4 .list .item .tits {
  font-size: 14px;
}
</style>
