<!-- -->
<template>
  <div class="frame">
    <Banner />
    <div class="expert">
      <div class="tabsBox w1280 animation">
        <div class="item f-cb">
          <div class="tits">科室专家</div>
          <div class="itemRight f-cb">
            <div class="tabs f-cb">
              <a
                href="javascript:;"
                :class="{ on: !activeSubject.id }"
                @click="changeHouse()"
                >全部</a
              >
              <a
                href="javascript:;"
                :class="{ on: activeSubject.id == item.id }"
                v-for="item in subjectList"
                :key="item.id"
                @click="changeHouse(item.id)"
                >{{ item.officeName }}</a
              >
            </div>
            <div class="titBox">
              <div
                class="tit f-cb"
                style="display: block"
                v-if="activeSubject.id"
              >
                <a
                  :class="{ on: activeType.id == item.id }"
                  href="javascript:;"
                  v-for="item in activeSubject.children"
                  :key="item.id"
                  @click="changeType(item)"
                  >{{ item.officeName }}</a
                >
              </div>
              <div class="all" v-else>
                <div
                  class="tit f-cb"
                  style="display: block"
                  v-for="item in subjectList"
                  :key="item.id"
                >
                  <a
                    :class="{ on: activeType.id == child.id }"
                    href="javascript:;"
                    v-for="child in item.children"
                    :key="child.id"
                    @click="changeType(child)"
                    >{{ child.officeName }}</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="box1 w1280">
        <div class="top f-cb animation">
          <div class="home">
            <router-link to="/">首页</router-link>
            <span></span>
            <i>{{ activeType.officeName || "专家团队" }}</i>
          </div>
          <div class="searchBox">
            <input
              type="text"
              name="keyword"
              class="text"
              placeholder="请输入专家姓名"
              v-model="queryParams.name"
            />
            <button type="button" class="submit" @click="startSearch"></button>
          </div>
        </div>
        <div class="list">
          <ul class="f-cb">
            <li class="animation" v-for="item in list" :key="item.id">
              <router-link :to="$route.path + '/' + item.id">
                <div class="pic">
                  <img width="100%" :src="item.imageHeaderUrl" />
                </div>
                <div class="tits">
                  {{ item.name }}<span>{{ item.position }}</span>
                </div>
                <div class="tit">专业擅长：{{ item.specializeIn }}</div>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Banner from "../components/banner.vue";
import { Store } from "@/store/index.js";
import { mapState } from "pinia";
export default {
  data() {
    return {
      activeSubject: {
        officeName: "",
        children: [],
      },
      activeType: {},
      queryParams: {
        name: "",
        officeCode: "", // 科室code
      },
      list: [],
    };
  },
  components: { Banner },
  computed: {
    ...mapState(Store, ["subjectList"]),
  },
  created() {
    this.getList();
  },
  methods: {
    // 改变科室
    changeHouse(id) {
      const subject = this.subjectList.find((ele) => ele.id == id);
      this.activeSubject = subject || {
        officeName: "",
        children: [],
      };
      this.activeType = {};
      this.queryParams.officeCode = this.activeSubject.code;
      this.getList();
    },
    // 改变类型
    changeType(item) {
      let obj = item;
      // 取消选择
      if (this.activeType.officeName == item.officeName) {
        obj = {};
      }
      // 选择
      this.activeType = obj;
      this.queryParams.officeCode = obj.code;
      this.getList();
    },
    startSearch() {
      this.getList();
    },
    async getList() {
      let res = await this.$api.getAllDoctor(this.queryParams);
      this.list = res.data;
    },
  },
  beforeRouteLeave(to, from, next) {
    from.meta.keepAlive = to.path.indexOf("/teamlist_") != -1;
    next();
  },
};
</script>

<style lang="scss" scoped>
.list .f-cb {
  display: flex;
  flex-wrap: wrap;
}
</style>
