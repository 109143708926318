<!-- -->
<template>
  <div class="frame">
    <Banner />
    <BreadCrumb />
    <div class="contact">
      <div class="title animation">
        <img src="https://www.sclsnk.com/file/skin/picture/contact_txt.png" />
        <div class="t">加入我们</div>
      </div>
      <div class="tabs animation">
        <div class="w1280">
          <a
            href="javascript:;"
            :class="{ t1: true, on: queryParams.jobCode == item.code }"
            v-for="item in types"
            :key="item.id"
            @click="changeType(item)"
            >{{ item.name }}</a
          >
        </div>
      </div>

      <div class="itemBox w1280">
        <div class="item animation">
          <div class="tab f-cb">
            <a
              href="javascript:;"
              :class="{ on: active == item.id }"
              v-for="item in list"
              :key="item.id"
              @click="changeActive(item)"
              >{{ item.jobName }}</a
            >
          </div>
          <div class="list animation">
            <ul>
              <li
                :style="{ display: active == item.id ? 'block' : 'none' }"
                v-for="item in list"
                :key="item.id"
              >
                <div class="tits">{{ item.jobName }}</div>
                <div class="tit" v-html="item.content"></div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Banner from "../components/banner.vue";
import BreadCrumb from "../components/breadCrumb.vue";
export default {
  data() {
    return {
      types: [],
      queryParams: {
        jobCode: "",
      },
      list: [],
      active: 0,
    };
  },
  components: { Banner, BreadCrumb },
  created() {
    this.getType();
  },
  methods: {
    getType() {
      this.$api
        .getDicByParentCode({ code: "bc85e30d532d43a38dc45ad043db5e19" })
        .then((res) => {
          this.types = res.data[0].children;
          this.changeType(this.types[0]);
        });
    },
    changeType(item) {
      this.queryParams.jobCode = item.code;
      this.getList();
    },
    async getList() {
      let res = await this.$api.getJobList(this.queryParams);
      this.list = res.data;
      this.changeActive(this.list[0]);
    },
    changeActive(item) {
      this.active = item.id;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
