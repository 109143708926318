<!-- -->
<template>
  <div class="frame">
    <Banner />
    <BreadCrumb />
    <div class="news">
      <div class="top f-cb animation">
        <div class="w1280 f-cb">
          <div id="swiper" class="imgBox">
            <ul class="swiper-wrapper">
              <li
                class="swiper-slide"
                v-for="item in swiperList"
                :key="item.id"
              >
                <router-link
                  :to="item.templateDesc + '_' + item.dictId + '/' + item.id"
                >
                  <img
                    width="80%"
                    :src="item.newsImageUrl"
                    :title="item.title"
                    :alt="item.title"
                  />
                </router-link>
              </li>
            </ul>
          </div>
          <div class="list">
            <ul>
              <li
                :class="{ on: activeIdx == idx }"
                v-for="(item, idx) in swiperList"
                :key="item.id"
              >
                <router-link
                  :to="item.templateDesc + '_' + item.dictId + '/' + item.id"
                  >{{ item.title }}</router-link
                >
                <div class="t">
                  {{ item.publishTime | handleTime(item.createTime) }}<em>|</em
                  >{{ globalConfig.name }}
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="content w1280 f-cb">
        <div class="conLeft">
          <ul id="datalist">
            <li class="animation" v-for="item in list" :key="item.id">
              <router-link
                :to="item.templateDesc + '_' + item.dictId + '/' + item.id"
                class="f-cb"
              >
                <div class="pic">
                  <img
                    width="100%"
                    :src="item.newsImageUrl"
                    :title="item.title"
                    :alt="item.title"
                  />
                </div>
                <div class="con">
                  <div class="tits">{{ item.title }}</div>
                  <div class="tit">{{ item.description }}</div>
                  <div class="time">
                    {{ item.publishTime | handleTime(item.createTime) }}
                  </div>
                </div>
              </router-link>
            </li>
          </ul>
          <el-pagination
            background
            layout="total, prev, pager, next"
            :total="total"
            :current-page="queryParams.pageIndex"
            :page-size="queryParams.pageSize"
            @current-change="currentChange"
          >
          </el-pagination>
        </div>
        <div class="conRight">
          <div class="item1 animation">
            <div class="title">热门新闻</div>
            <ul>
              <li v-for="(item, idx) in hotList" :key="item.id">
                <router-link
                  :to="item.templateDesc + '_' + item.dictId + '/' + item.id"
                  class="f-cb"
                >
                  <span>{{ idx + 1 }}</span>
                  <div class="t">{{ item.title }}</div>
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Banner from "../components/banner.vue";
import BreadCrumb from "../components/breadCrumb.vue";
import { Store } from "@/store/index.js";
import { mapState } from "pinia";
export default {
  data() {
    return {
      isFirst: true, // 第一次请求
      swiperList: [],
      activeIdx: 0,
      queryParams: {
        pageIndex: 1,
        pageSize: 15,
        dictIds: undefined,
      },
      total: 0,
      list: [],
      hotList: [],
    };
  },
  components: { Banner, BreadCrumb },
  computed: {
    ...mapState(Store, ["globalConfig"]),
  },
  created() {
    this.queryParams.dictIds = this.$route.params.id;
  },
  mounted() {
    this.getList();
    this.getHotList();
  },
  methods: {
    currentChange(val) {
      this.queryParams.pageIndex = val;
      window.scrollTo({ top: 0 });
      this.getList();
    },
    getList() {
      this.$api.getNewsList(this.queryParams).then((res) => {
        this.list = res.data.list;
        this.total = res.data.total;

        if (this.isFirst) {
          this.isFirst = false;
          this.swiperList = this.list.slice(0, 4);
          setTimeout(() => {
            this.initSwiper();
          }, 100);
        }
      });
    },
    initSwiper() {
      let vm = this;
      this.swiper = new window.Swiper("#swiper", {
        loop: true,
        autoplay: true,
        on: {
          slideChangeTransitionEnd() {
            vm.activeIdx = this.realIndex;
          },
        },
      });
    },
    // 热门新闻
    getHotList() {
      const params = { ...this.queryParams };
      params.recommendFlag = 1;
      params.pageSize = 15;
      this.$api.getNewsList(this.queryParams).then((res) => {
        this.hotList = res.data.list;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#swiper {
  height: 400px;
  position: relative;
  overflow: hidden;
}
</style>
