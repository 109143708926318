<template>
  <div class="aboutNav">
    <div class="navNew">
      <div class="tit">
        <div style="float: left" v-for="item in subList" :key="item.id">
          <router-link
            :to="item.templateDesc + '_' + item.id"
            v-if="item.jumpType == 0"
            >{{ item.name }}</router-link
          >
          <a :href="item.bannerUrl" v-else>{{ item.name }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Store } from "@/store/index.js";
import { mapState } from "pinia";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapState(Store, ["navList"]),
    // 扁平化导航条
    nav() {
      return this.flatNav.find((ele) => ele.id == this.$route.params.id);
    },
    // 扁平化导航条
    flatNav() {
      return this.navList.flatMap((ele) => [
        ele,
        ...ele.children.flatMap((child) => [child, ...child.children]),
      ]);
    },
    subList() {
      const first = this.flatNav.find((ele) => ele.id == this.$route.params.id);
      if (!first) return [];
      // 若为二级字典，则反找一级字典的children
      if (first.level == 2) {
        return this.navList.find((ele) => ele.code == first.parentCode)
          .children;
      }
      // 若为三级字典，则反找一级字典的children
      if (first.level == 3) {
        const parentCode = first.queryCode.split("#")[1];
        return this.navList.find((ele) => ele.code == parentCode).children;
      }
      return first.children;
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>
